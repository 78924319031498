import React, { useEffect, useRef, useState } from 'react'

import FullCalendar from '@fullcalendar/react'

import {
    useTheme,
    IconButton,
    Tooltip,
    Slide,
    Paper,
    Modal,
    FormControlLabel,
    Switch,
    Badge,
} from '@material-ui/core'

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import TuneIcon from '@material-ui/icons/Tune'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import ListIcon from '@material-ui/icons/List'

import {
    Calendar,
    Container,
    FilterChips,
    SideDrawer,
    WorkorderFilterForm,
} from '../../components'

import {
    GetWorkOrderListRequest,
    WORKORDER_TYPE_ACTION_ITEM,
    WORKORDER_TYPE_SERVICE_ORDER,
    WorkorderFilterState,
    workorderContext,
} from '../../contexts'
import { WorkorderStatus, WorkorderType } from '../../models'
import {
    useAppDispatch,
    useAptConfig,
    useCompany,
    useDeadline,
    useLease,
    useRootInfrastructure,
    useSchedule,
    useService,
    useUser,
} from '../../hooks'
import {
    LocationSelection,
    RootState,
    getChangeOrderConfigListRequest,
} from '../../store'

import { CalendarEventType } from './types'
import { DeadlineListModal } from './ViewComponents'
import { ScheduleListModal } from '../ScheduleList/ScheduleListModal'
import { AccountTree, Link } from '@material-ui/icons'
import { ChangeOrderListModal } from '../ChangeOrder/ChangeOrderListModal'
import { useSelector } from 'react-redux'
import { MoveOutRuleModal } from '../MoveOutRule/MoveOutRuleModal'
import { useMoveOutRules } from '../../hooks/useMoveOutRules'
import { useOnCallSchedule } from '../../hooks/useOnCallSchedule'
import { useUserCalendarConfigs } from '../../hooks/useUserCalendarConfigs'
import { CalendarEventButtons } from './ViewComponents/Calendar/CalendarEventButtons'
import { useDayOff } from '../../hooks/useDayOff'
import { LeaseModal } from './ViewComponents/LeaseModal/LeaseModal'
import { useChangeOrder } from '../../hooks/useChangeOrder'

export const ScheduleHome = () => {
    const theme = useTheme()

    const cRef = useRef<FullCalendar>(null)

    const {
        getWorkorderClusters,
        workorderClusters,
        finderFilterSelection: _finderFilterSelection,
        workorderFilterState,
        setWorkorderFilterState,
        getChipData,
        finderCreateSelection,
        createWorkorders,
        workorderSelectionMap: ezNowWorkorderSelectionMap,
        setWorkorderSelectionMap: setEZNowWorkorderSelectionMap,
    } = workorderContext()

    const {
        scheduleList,
        getScheduleList,
        createSchedule,
        updateSchedule,
        loadingCreate,
        loadingUpdate,
    } = useSchedule()

    const finderFilterSelection = _finderFilterSelection.getCustomSelection
        ? _finderFilterSelection.getCustomSelection((location) => {
              loadClusters(workorderFilterState, location)
          })
        : _finderFilterSelection

    const { tree } = useRootInfrastructure(true)
    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })
    const { userList, workspaceUser, getUserList } = useUser()
    const { getLeaseClusterList, leaseClusterList } = useLease()

    const {
        apartmentDeadlineList,
        getApartmentDeadlines,
        createDeadline,
        getDeadlineDetail,
        transitionDeadline,
        incrementMessageCount,
    } = useDeadline()

    const dispatch = useAppDispatch()

    const {
        unitConfigList,
        inspectionTypeList,
        getAreaConfigMap,
        getUnitConfigMap,
    } = useAptConfig({
        inventoryConfigList: true,
        customStatusList: true,
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
        inspectionTypeList: true,
    })

    const {
        moveOutRuleList,
        selectedMoveOutRule,
        setSelectedMoveOutRule,
        getMoveOutRuleList,
        createMoveOutRule,
        deleteMoveOutRule,
        createOrUpdateMoveOutStep,
        deleteMoveOutStep,
        createOrUpdateVendorRule,
        deleteMoveOutVendorRule,
    } = useMoveOutRules()

    const {
        onCallDays,
        getOnCallDays,
        createOnCallDays,
        deleteOnCallDay,
    } = useOnCallSchedule()

    const {
        userCalendarConfigs,
        createUserCalendarConfig,
        deleteUserCalendarConfig,
        editUserCalendarConfig,
    } = useUserCalendarConfigs()

    const changeOrderController = useChangeOrder()

    const { dayOffList, getDayOffList, addDayOff, deleteDayOff } = useDayOff()

    const [visibleEventTypes, setVisibleEventTypes] = useState<
        CalendarEventType[]
    >([])

    const [filterOpen, setFilterOpen] = useState(false)
    const [deadlineListOpen, setDeadlineListOpen] = useState(false)
    const [projectListModalOpen, setProjectListModalOpen] = useState(false)
    const [showArchivedProjects, setShowArchivedProjects] = useState(false)
    const [changeOrderModalOpen, setChangeOrderModalOpen] = useState(false)
    const [moveOutRuleModalOpen, setMoveOutRuleModalOpen] = useState(false)
    const [moveOutModalOpen, setMoveOutModalOpen] = useState(false)

    const changeOrderConfigList = useSelector(
        (state: RootState) => state.aptConfig.changeOrderConfigList,
    )

    const loadClusters = async (
        filterState: WorkorderFilterState,
        locationSelection: LocationSelection,
    ) => {
        const request: GetWorkOrderListRequest = { params: {} }

        if (locationSelection.unit.length > 0) {
            const unitIdArray: string[] = []
            Object.keys(locationSelection.unit).forEach((strKey) => {
                if (strKey === 'length') {
                    return
                }

                const unitId = Number(strKey)

                if (locationSelection.unit[unitId]) {
                    unitIdArray.push(strKey)
                }
            })
            request.params!.units = convertArrayToCommaSeperatedStr(unitIdArray)
        }

        if (filterState.workorderType === WORKORDER_TYPE_SERVICE_ORDER) {
            request.params!.workorder_type = WorkorderType.SERVICE_ORDER
        }

        if (filterState.workorderType === WORKORDER_TYPE_ACTION_ITEM) {
            request.params!.workorder_type = WorkorderType.ACTION_ITEM
        }

        if (filterState.unitTypeIds.length > 0) {
            request.params!.unit_type_list = convertArrayToCommaSeperatedStr(
                filterState.unitTypeIds,
            )
        }

        if (filterState.userIds.length > 0) {
            request.params!.user_list = convertArrayToCommaSeperatedStr(
                filterState.userIds,
            )
        }

        if (filterState.vendorIds.length > 0) {
            request.params!.vendor_list = convertArrayToCommaSeperatedStr(
                filterState.vendorIds,
            )
        }

        if (filterState.serviceIds.length > 0) {
            request.params!.service_list = convertArrayToCommaSeperatedStr(
                filterState.serviceIds,
            )
        }

        if (filterState.schedule > -1) {
            request.params!.schedule = filterState.schedule
        }

        const statusList = Object.keys(filterState.statusFilter).reduce<
            WorkorderStatus[]
        >((prev, statusKey) => {
            const status: WorkorderStatus = statusKey as any

            if (filterState.statusFilter[status]) {
                return prev.concat(status)
            }

            return prev
        }, [])

        if (statusList.length > 0) {
            request.params!.status_list = convertArrayToCommaSeperatedStr(
                statusList,
            )
        }

        if (filterState.priority) {
            request.params!.priority = true
        }

        if (filterState.hasMessage) {
            request.params!.has_message = true
        }

        if (filterState.hasUnread) {
            request.params!.has_unread = true
        }

        if (filterState.isGhost) {
            request.params!.is_ghost = true
        }

        if (filterState.isBehind) {
            request.params!.is_behind = true
        }

        if (filterState.hasChangeOrder) {
            request.params!.has_change_order = true
        }

        if (filterState.scheduleActive) {
            request.params!.schedule_active = true
        }

        getWorkorderClusters(request)
    }

    const loadSchedules = async () => {
        getScheduleList({})
    }

    const onChangeFilters = (fs: WorkorderFilterState) => {
        loadClusters(fs, finderFilterSelection.selection)
        setWorkorderFilterState(fs)
    }

    const onReloadData = () => {
        loadClusters(workorderFilterState, finderFilterSelection.selection)
        loadSchedules()
        getLeaseClusterList({ params: { exclude_children: true } })
    }

    useEffect(() => {
        loadClusters(
            { ...workorderFilterState, scheduleActive: !showArchivedProjects },
            finderFilterSelection.selection,
        )
        loadSchedules()
        getApartmentDeadlines()
        getLeaseClusterList({ params: { exclude_children: true } })
        dispatch(getChangeOrderConfigListRequest({}))
        getMoveOutRuleList()
        getOnCallDays()
        getUserList({ params: { my_team: true } })
        getDayOffList()

        const newDate = new Date()
        newDate.setMonth(newDate.getMonth() - 6)
        changeOrderController.getChangeOrderList({
            lower_bound_date: newDate.toISOString(),
        })
    }, [])

    useEffect(() => {
        const autoDisplayEventTypes = userCalendarConfigs
            .filter((config) => config.auto_display)
            .map((config) => config.event_type)
        setVisibleEventTypes(autoDisplayEventTypes)
    }, [userCalendarConfigs])

    const chipData = getChipData({
        onClickHandler: (options) => {
            loadClusters(options.filterState, finderFilterSelection.selection)
        },
        scheduleList: scheduleList,
    })

    const fastForward = (direction: -1 | 1) => {
        if (cRef.current) {
            const calendar = cRef.current.getApi()

            const currentDate = calendar.getDate()

            let minDistanceCandidate = { distance: 0, date: currentDate }

            if (visibleEventTypes.includes(CalendarEventType.PROJECT)) {
                const filteredSchedules = scheduleList.filter((sch) => {
                    return sch.active || showArchivedProjects
                })
                const nextScheduleDate = findNextDirectionalDate(
                    currentDate,
                    filteredSchedules,
                    (sch) => new Date(sch.start_date),
                    direction,
                )

                if (nextScheduleDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextScheduleDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextScheduleDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.DEADLINE)) {
                const nextDeadlineDate = findNextDirectionalDate(
                    currentDate,
                    apartmentDeadlineList ?? [],
                    (dl) => new Date(dl.deadline.date),
                    direction,
                )

                if (nextDeadlineDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextDeadlineDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextDeadlineDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.MOVE_OUT)) {
                const nextMoveOut = findNextDirectionalDate(
                    currentDate,
                    leaseClusterList?.move_out ?? [],
                    (lc) => new Date(lc.date),
                    direction,
                )

                if (nextMoveOut.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextMoveOut.distance < minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextMoveOut
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.MOVE_IN)) {
                const nextMoveIn = findNextDirectionalDate(
                    currentDate,
                    leaseClusterList?.move_in ?? [],
                    (lc) => new Date(lc.date),
                    direction,
                )

                if (nextMoveIn.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextMoveIn.distance < minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextMoveIn
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.ACTION_ITEM)) {
                const actionItemClusters =
                    workorderClusters?.filter(
                        (cluster) =>
                            cluster.workorder_type ===
                            WorkorderType.ACTION_ITEM,
                    ) ?? []

                const nextActionItemDate = findNextDirectionalDate(
                    currentDate,
                    actionItemClusters,
                    (cluster) => new Date(cluster.start_date),
                    direction,
                )

                if (nextActionItemDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextActionItemDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextActionItemDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.SERVICE_ORDER)) {
                const serviceOrderClusters =
                    workorderClusters?.filter(
                        (cluster) =>
                            cluster.workorder_type ===
                            WorkorderType.SERVICE_ORDER,
                    ) ?? []

                const nextServiceOrderDate = findNextDirectionalDate(
                    currentDate,
                    serviceOrderClusters,
                    (cluster) => new Date(cluster.start_date),
                    direction,
                )

                if (nextServiceOrderDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextServiceOrderDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextServiceOrderDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.SERVICE_REQUEST)) {
                const serviceOrderClusters =
                    workorderClusters?.filter(
                        (cluster) =>
                            cluster.workorder_type ===
                            WorkorderType.SERVICE_REQUEST,
                    ) ?? []

                const nextServiceOrderDate = findNextDirectionalDate(
                    currentDate,
                    serviceOrderClusters,
                    (cluster) => new Date(cluster.start_date),
                    direction,
                )

                if (nextServiceOrderDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextServiceOrderDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextServiceOrderDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.ON_CALL)) {
                const nextServiceOrderDate = findNextDirectionalDate(
                    currentDate,
                    onCallDays,
                    (onCallDay) => new Date(onCallDay.start_time),
                    direction,
                )

                if (nextServiceOrderDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextServiceOrderDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextServiceOrderDate
                    }
                }
            }

            if (visibleEventTypes.includes(CalendarEventType.DAY_OFF)) {
                const nextServiceOrderDate = findNextDirectionalDate(
                    currentDate,
                    dayOffList,
                    (dayOff) => new Date(dayOff.date),
                    direction,
                )

                if (nextServiceOrderDate.distance !== 0) {
                    if (
                        minDistanceCandidate.distance === 0 ||
                        nextServiceOrderDate.distance <
                            minDistanceCandidate.distance
                    ) {
                        minDistanceCandidate = nextServiceOrderDate
                    }
                }
            }

            calendar.gotoDate(minDistanceCandidate.date)
        }
    }

    return (
        <>
            <Container
                style={{
                    flexDirection: 'column',
                    height: 'calc(100vh - 104px)',
                    flex: 1,
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        minHeight: 100,
                        maxHeight: 100,
                        alignItems: 'center',
                    }}
                >
                    <CalendarEventButtons
                        visibleEventTypes={visibleEventTypes}
                        userCalendarConfigs={userCalendarConfigs}
                        setVisibleEventTypes={setVisibleEventTypes}
                        createUserCalendarConfig={createUserCalendarConfig}
                        editUserCalendarConfig={editUserCalendarConfig}
                        deleteUserCalendarConfig={deleteUserCalendarConfig}
                    />

                    <Tooltip title="Filters">
                        <IconButton onClick={() => setFilterOpen(!filterOpen)}>
                            <TuneIcon
                                htmlColor={
                                    filterOpen
                                        ? theme.palette.primary.main
                                        : undefined
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <FilterChips theme={theme} chips={chipData} />

                    <div style={{ flex: 1 }} />

                    {visibleEventTypes.includes(CalendarEventType.PROJECT) && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showArchivedProjects}
                                    color="primary"
                                    onChange={(e) => {
                                        setShowArchivedProjects(
                                            !showArchivedProjects,
                                        )
                                        onChangeFilters({
                                            ...workorderFilterState,
                                            scheduleActive: showArchivedProjects,
                                        })
                                    }}
                                />
                            }
                            labelPlacement="end"
                            label="Show Archived Projects"
                            style={{ marginBottom: theme.spacing(1) }}
                        />
                    )}

                    <Tooltip title="Move Outs">
                        <IconButton
                            onClick={() =>
                                setMoveOutModalOpen(!moveOutModalOpen)
                            }
                        >
                            <EmojiPeopleIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Move Out Rules">
                        <IconButton
                            onClick={() =>
                                setMoveOutRuleModalOpen(!moveOutRuleModalOpen)
                            }
                        >
                            <AccountTree />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Change Orders">
                        <IconButton
                            onClick={() =>
                                setChangeOrderModalOpen(!changeOrderModalOpen)
                            }
                        >
                            <Badge
                                badgeContent={
                                    changeOrderController.pendingChangeOrderCount
                                }
                                color={
                                    changeOrderController.pendingChangeOrderCount >
                                    0
                                        ? 'primary'
                                        : 'secondary'
                                }
                            >
                                <Link />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Project List View">
                        <IconButton
                            onClick={() =>
                                setProjectListModalOpen(!projectListModalOpen)
                            }
                        >
                            <AssignmentTurnedInIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Deadline list view">
                        <IconButton
                            onClick={() =>
                                setDeadlineListOpen(!deadlineListOpen)
                            }
                        >
                            <ListIcon
                                color={
                                    deadlineListOpen ? 'secondary' : undefined
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Skip to previous">
                        <IconButton onClick={() => fastForward(-1)}>
                            <DoubleArrowIcon
                                style={{ transform: 'rotate(180deg)' }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Skip to next">
                        <IconButton onClick={() => fastForward(1)}>
                            <DoubleArrowIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Container>

                {/* Body */}
                <Calendar
                    visibleEvents={visibleEventTypes}
                    apartmentDeadlines={apartmentDeadlineList ?? []}
                    theme={theme}
                    workorderClusters={workorderClusters ?? []}
                    moveOutClusters={leaseClusterList?.move_out ?? []}
                    moveInClusters={leaseClusterList?.move_in ?? []}
                    projects={scheduleList}
                    createSchedule={createSchedule}
                    updateSchedule={updateSchedule}
                    transitionDeadline={transitionDeadline}
                    createDeadline={createDeadline}
                    workspaceUser={workspaceUser}
                    loading={loadingCreate || loadingUpdate}
                    getDeadlineDetail={getDeadlineDetail}
                    incrementMessageCount={incrementMessageCount}
                    ref={cRef}
                    root={tree.root}
                    finderSelection={finderCreateSelection}
                    serviceList={serviceList}
                    userList={userList}
                    vendorList={vendorList}
                    areaConfigMap={getAreaConfigMap()}
                    unitConfigMap={getUnitConfigMap()}
                    createWorkorders={createWorkorders}
                    ezNowWorkorderSelectionMap={ezNowWorkorderSelectionMap}
                    setEZNowWorkorderSelectionMap={
                        setEZNowWorkorderSelectionMap
                    }
                    reloadWorkorderClusters={() => {
                        loadClusters(
                            workorderFilterState,
                            finderFilterSelection.selection,
                        )
                    }}
                    showArchivedProjects={showArchivedProjects}
                    inspectionTypeList={inspectionTypeList ?? []}
                    onReloadData={onReloadData}
                    onCallDays={onCallDays}
                    createOnCallDays={createOnCallDays}
                    deleteOnCallDay={deleteOnCallDay}
                    dayOffList={dayOffList}
                    addDayOff={addDayOff}
                    deleteDayOff={deleteDayOff}
                />
            </Container>

            <ChangeOrderListModal
                open={changeOrderModalOpen}
                theme={theme}
                changeOrderConfigList={changeOrderConfigList ?? []}
                workspaceUser={workspaceUser}
                changeOrderController={changeOrderController}
                onClose={() => setChangeOrderModalOpen(false)}
            />

            <DeadlineListModal
                theme={theme}
                open={deadlineListOpen}
                deadlines={apartmentDeadlineList ?? []}
                transitionDeadline={transitionDeadline}
                onClose={() => setDeadlineListOpen(false)}
            />

            <Modal
                open={projectListModalOpen}
                onClose={() => {
                    setProjectListModalOpen(false)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={projectListModalOpen}>
                    <Paper style={{}}>
                        <ScheduleListModal />
                    </Paper>
                </Slide>
            </Modal>

            <SideDrawer
                width={400}
                open={filterOpen}
                handleClose={() => {
                    setFilterOpen(false)
                }}
            >
                <WorkorderFilterForm
                    theme={theme}
                    root={tree.root}
                    finderSelection={finderFilterSelection}
                    serviceList={serviceList}
                    vendorList={vendorList}
                    userList={userList}
                    unitConfigList={unitConfigList}
                    workorderFilterState={workorderFilterState}
                    setWorkorderFilterState={onChangeFilters}
                    scheduleList={scheduleList}
                    disabledSections={['timeline']}
                />
            </SideDrawer>

            <MoveOutRuleModal
                open={moveOutRuleModalOpen}
                onClose={() => setMoveOutRuleModalOpen(false)}
                theme={theme}
                moveOutRuleList={moveOutRuleList}
                createMoveOutRule={createMoveOutRule}
                selectedMoveOutRule={selectedMoveOutRule}
                setSelectedMoveOutRule={setSelectedMoveOutRule}
                serviceList={serviceList}
                inspectionTypeList={inspectionTypeList ?? []}
                deleteMoveOutRule={deleteMoveOutRule}
                createOrUpdateMoveOutStep={createOrUpdateMoveOutStep}
                deleteMoveOutStep={deleteMoveOutStep}
                vendorList={vendorList}
                createOrUpdateVendorRule={createOrUpdateVendorRule}
                onDeleteVendorRule={deleteMoveOutVendorRule}
            />

            <LeaseModal
                open={
                    moveOutModalOpen
                        ? {
                              type: CalendarEventType.MOVE_OUT,
                              leaseCluster: null,
                          }
                        : null
                }
                handleClose={() => {
                    setMoveOutModalOpen(false)
                }}
                theme={theme}
                vendorList={vendorList}
                serviceList={serviceList}
                inspectionTypeList={inspectionTypeList ?? []}
                onReloadData={onReloadData}
            />
        </>
    )
}

const convertArrayToCommaSeperatedStr = (values: any[]) => {
    let retVal = ''
    values.forEach((val) => {
        retVal += `${val},`
    })

    if (retVal.charAt(retVal.length - 1) === ',') {
        retVal = retVal.substring(0, retVal.length - 1)
    }

    return retVal
}

const findDistance = (
    currentDate: Date,
    compareDate: Date,
    direction: -1 | 1,
) => {
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()

    const compareMonth = compareDate.getMonth()
    const compareYear = compareDate.getFullYear()

    if (currentMonth === compareMonth && currentYear === compareYear) {
        return null
    }

    // if the direction is -1 the user is attempting to move into the past.
    // if the compare date is > current date ignore the value

    const currentT = currentDate.getTime()
    const compareT = compareDate.getTime()

    if (direction === -1) {
        // The direction is -1, the user is moving into the past
        // if the compare date is > current date ignore the value
        if (compareT > currentT) {
            return null
        }
    } else if (direction === 1) {
        // The direction is +1, the user is moving into the future
        // if the compare date is < current date ignore the value
        if (compareT < currentT) {
            return null
        }
    }

    // Return the absolute value of the distance
    return Math.abs(currentDate.getTime() - compareDate.getTime())
}

const findNextDirectionalDate = <T extends object>(
    initialDate: Date,
    dateExtractables: T[],
    dateExtractor: (dateExtractable: T) => Date,
    direction: -1 | 1,
) => {
    let candidate = {
        date: initialDate,
        distance: 0,
    }

    dateExtractables.forEach((dateExtractable) => {
        // Get the start date of the candidate
        const candidateDate = dateExtractor(dateExtractable)
        // Find the distance between the date in the viewport and the schedule date
        const currentDistance = findDistance(
            initialDate,
            candidateDate,
            direction,
        )

        if (currentDistance === null) {
            // The compare date is not worth considering because it
            // it either shares a date & year with the view port
            // OR it is in the opposite direction of the users intent
            return
        }

        // if there was not a previous nearest date, or the new date
        // is closer to the schedule date replace the nearest date data
        if (candidate.distance === 0 || candidate.distance > currentDistance) {
            candidate = {
                date: candidateDate,
                distance: currentDistance,
            }
        }
    })

    return candidate
}
