import React, { useEffect, useRef, useState } from 'react'

import {
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import { useAppDispatch, useUser } from '../../hooks'
import { getStatusGroupList, getStatusList, RootState } from '../../store'
import { useSelector } from 'react-redux'
import {
    CellData,
    Container,
    HeadCell,
    RenderRow,
    RowData,
    SideDrawerContainer,
    SimpleTable,
    StatusBadge,
} from '../../components'
import { StatusGroup } from '../../models'
import { useStyles } from '../../styles'
import { Add, GetApp, CloudUpload, Toc } from '@material-ui/icons'

import { StatusGroupSideDrawer } from '.'
import { axiosInstance } from '../../helpers'
import { StatusModal } from './StatusModal'

export const StatusGroupConfig = () => {
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()

    const attachmentRef = useRef<HTMLInputElement>(null)

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<StatusGroup>()
    const [statusModalOpen, setStatusModalOpen] = useState(false)

    const statusGroupList = useSelector(
        (state: RootState) => state.aptConfig.statusGroupList,
    )

    const { workspaceUser } = useUser()

    useEffect(() => {
        dispatch(getStatusGroupList({}))

        //called here so that it is not re called every time the drawer is opened
        dispatch(getStatusList({}))
    }, [])
    //table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface StatusGroupgRowType extends RowData {
        name: CellData<string>
        defaultStatus: CellData<JSX.Element>
        customStatuses: CellData<JSX.Element>
        statusGroup: CellData<StatusGroup>
    }

    const headCells: HeadCell<StatusGroupgRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'defaultStatus',
            label: 'Default Status',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'customStatuses',
            label: 'Statuses',
            style: headCellStyle,
        },
    ]

    const createRow = (statusGroup: StatusGroup): StatusGroupgRowType => {
        const textStyle: React.CSSProperties = {
            textAlign: 'center',
            fontWeight: 'bold',
        }
        const contianerSize: React.CSSProperties = { width: 140, height: 52 }
        const defaultStatusBadge = statusGroup.default_status ? (
            <StatusBadge
                text={statusGroup.default_status.name}
                customStyle={{
                    container: {
                        backgroundColor: statusGroup.default_status.color,
                        ...contianerSize,
                    },
                    text: textStyle,
                }}
            />
        ) : (
            <div />
        )

        const statusBadges = (
            <Container justifyContent="flex-start" style={{ flexWrap: 'wrap' }}>
                {statusGroup.custom_statuses?.map((s) => (
                    <StatusBadge
                        key={s.id}
                        text={s.name}
                        customStyle={{
                            container: {
                                margin: theme.spacing(0, 1, 1, 0),
                                backgroundColor: s.color,
                                ...contianerSize,
                            },
                            text: textStyle,
                        }}
                    />
                ))}
            </Container>
        )

        return {
            name: { value: statusGroup.name, sortValue: statusGroup.name },
            customStatuses: {
                value: statusBadges,
                sortValue: statusGroup.custom_statuses?.length ?? 0,
            },
            defaultStatus: {
                value: defaultStatusBadge,
                sortValue: statusGroup?.default_status?.id ?? -1,
            },
            statusGroup: {
                value: statusGroup,
                sortValue: 1,
            },
        }
    }

    interface StatusGroupRowProps {
        row: StatusGroupgRowType
    }

    const StatusGroupRow: RenderRow<StatusGroupRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`INV_CONF_ROW_${row.statusGroup.value.id}`}
                style={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedGroup &&
                        selectedGroup.id === row.statusGroup.value.id
                            ? theme.palette.grey[300]
                            : undefined,
                }}
                className={classes.hoverGrey300}
                onClick={() => {
                    setSelectedGroup(row.statusGroup.value)
                    setDrawerOpen(true)
                }}
            >
                <TableCell style={cellStyle}>{row.name.value}</TableCell>
                <TableCell style={cellStyle}>
                    {row.defaultStatus.value}
                </TableCell>
                <TableCell style={cellStyle}>
                    {row.customStatuses.value}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                direction="column"
                flex={1}
                style={{ height: 'calc(100vh - 104px)' }}
            >
                {/* header */}
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        Status Group
                    </span>

                    <Tooltip title="Status Modal">
                        <IconButton
                            onClick={() => {
                                setStatusModalOpen(true)
                            }}
                        >
                            <Toc fontSize="large" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Download Status Sheet">
                        <IconButton
                            onClick={() => {
                                axiosInstance
                                    .get(
                                        `company/apartment/${workspaceUser?.active_workspace.id}/status_sheet/`,
                                        {
                                            responseType: 'blob',
                                        },
                                    )
                                    .then((response) => {
                                        const url = window.URL.createObjectURL(
                                            new Blob([response.data]),
                                        )
                                        const link = document.createElement('a')
                                        link.href = url
                                        link.setAttribute(
                                            'download',
                                            'status_sheet.xlsx',
                                        )
                                        document.body.appendChild(link)
                                        link.click()
                                    })
                            }}
                        >
                            <GetApp fontSize="large" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Upload Status Sheet">
                        <IconButton
                            onClick={() => attachmentRef.current?.click()}
                        >
                            <CloudUpload fontSize="large" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="New Status Group">
                        <IconButton
                            onClick={() => {
                                setSelectedGroup(undefined)
                                setDrawerOpen(true)
                            }}
                        >
                            <Add fontSize="large" color="primary" />
                        </IconButton>
                    </Tooltip>
                </Container>
                {/* table */}
                <SimpleTable<StatusGroupgRowType>
                    rows={statusGroupList?.map((s) => createRow(s)) ?? []}
                    render={(row) => <StatusGroupRow row={row} />}
                    orderByDefault={'name'}
                    headCells={headCells}
                />
            </Container>
            <StatusGroupSideDrawer
                open={drawerOpen}
                statusGroup={selectedGroup}
                handleClose={() => {
                    setDrawerOpen(false)
                    setSelectedGroup(undefined)
                }}
            />

            <StatusModal
                open={statusModalOpen}
                onClose={() => {
                    setStatusModalOpen(false)
                }}
            />

            <input
                ref={attachmentRef}
                type="file"
                style={{ display: 'none' }}
                accept=".xlsx"
                onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const file = e.target.files ? e.target.files[0] : ''
                    const data = new FormData()
                    data.append('sheet', file)
                    axiosInstance
                        .post(
                            `company/apartment/${workspaceUser?.active_workspace.id}/status_sheet/`,
                            data,
                        )
                        .then(() => window.location.reload())
                }}
            />
        </SideDrawerContainer>
    )
}
