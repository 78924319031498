import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../hooks'
import { ListVendor, Company } from '../models'
import {
    createVendor,
    getApartmentList,
    getVendorList,
    removeVendorFromApt,
    RootState,
    setVendorList,
    updateApartment,
    updateVendor,
    updateVendorServices,
} from '../store'
import {
    CompanyDetailActionThunk,
    CreateVendorRequest,
    GetApartmentListActionThunk,
    GetApartmentListRequest,
    VendorListActionThunk,
    GetVendorListRequest,
    UpdateVendorRequest,
    UpdateVendorServicesRequest,
    VendorAndContractThunk,
    AddRemoveVendorAptRequest,
    GET_VENDOR_LIST_REQUEST,
    UpdateApartmentRequest,
} from '../store/company/types'

type UseCompany = {
    apartmentList: Company[]
    vendorList: ListVendor[]
    vendorsLoading: boolean
    getVendorList: (req: GetVendorListRequest) => VendorListActionThunk
    getApartmentList: (
        req: GetApartmentListRequest,
    ) => GetApartmentListActionThunk
    createVendor: (req: CreateVendorRequest) => VendorAndContractThunk
    removeVendor: (req: AddRemoveVendorAptRequest) => VendorListActionThunk
    updateVendor: (req: UpdateVendorRequest) => VendorAndContractThunk
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
    updateApartment: (req: UpdateApartmentRequest) => Promise<Company>
    setVendorList: (vendorList: ListVendor[]) => void
}

interface Options {
    getVendorList?: boolean
    cleanUp?: boolean
}

export const useCompany = (options?: Options): UseCompany => {
    const dispatch = useAppDispatch()
    const vendorList = useSelector(
        (state: RootState) => state.company.vendorList,
    )
    const apartmentList = useSelector(
        (state: RootState) => state.company.apartmentList,
    )

    const vendorsLoading = useSelector(
        (state: RootState) => state.company.isLoading[GET_VENDOR_LIST_REQUEST],
    )

    useEffect(() => {
        if (options?.getVendorList) {
            dispatch(getVendorList({ params: { my_team: true } }))
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setVendorList())
            }
        }
    }, [])

    return {
        apartmentList,
        vendorList,
        vendorsLoading,
        getApartmentList: (req) => dispatch(getApartmentList(req)),
        getVendorList: (req) => dispatch(getVendorList(req)),
        createVendor: (req) => dispatch(createVendor(req)),
        removeVendor: (req) => dispatch(removeVendorFromApt(req)),
        updateVendor: (req) => dispatch(updateVendor(req)),
        updateVendorServices: (req) => dispatch(updateVendorServices(req)),
        updateApartment: (req) => updateApartment(req),
        setVendorList: (vendorList: ListVendor[]) =>
            dispatch(setVendorList(vendorList)),
    }
}
