import React, { useState } from 'react'
import {
    CircularProgress,
    useTheme,
    TextField,
    Modal,
    Slide,
    Paper,
    Tabs,
    Tab,
    Button,
    Tooltip,
} from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { useAppDispatch, useAptConfig, useUser } from '../../hooks'
import { useSelector } from 'react-redux'
import {
    GET_AREA_CONFIG_LIST_REQUEST,
    GET_INVENTORY_CONFIG_LIST,
    GET_UNIT_CONFIG_LIST_REQUEST,
    RootState,
    setUnitConfigList,
} from '../../store'
import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { InventoryConfigRow } from './InventoryConfigRow'
import { AreaConfigRow } from './AreaConfigRow'
import { UnitConfigRow } from './UnitConfigRow'
import { CreateInventoryConfigForm } from './CreateInventoryConfigForm'
import { IdentifiableNamedObject, InventoryConfig } from '../../models'
import { InvConfigStatusCostDrawer } from '../InventoryConfig'
import { CreateAreaConfigForm } from './CreateAreaConfigForm'
import { CreateInfrastructureDrawer } from './CreateInfrastructureDrawer'
import { InvAreaConfigDrawer } from './InvAreaConfigDrawer'
import { IssueOptionsDrawer } from './IssueOptionsDrawer'
import InfoIcon from '@material-ui/icons/Info'
import { axiosInstance, isAccountManager } from '../../helpers'
import { InventoryGroupTabPanel } from './InventoryGroupTabPanel'
import { UnitAreaConfigMapForm } from './UnitAreaConfigMapForm'
import axios from 'axios'
import { toast } from 'react-toastify'

export const FORM_CLOSED = 0
export const INVENTORY_CONFIG = 1
export const INVENTORY_GROUP = 2
export const AREA_CONFIG = 3
export const UNIT_CONFIG = 4
export const ISSUE_CONFIG = 5

type ConfigSelection =
    | typeof INVENTORY_CONFIG
    | typeof INVENTORY_GROUP
    | typeof AREA_CONFIG
    | typeof UNIT_CONFIG
    | typeof ISSUE_CONFIG

type FormOpenState = typeof FORM_CLOSED | ConfigSelection

export interface DrawerState {
    formOpen: FormOpenState
    selected?: IdentifiableNamedObject
}

const closedDrawer: DrawerState = {
    formOpen: FORM_CLOSED,
}

export const InfrastructureConfig = () => {
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const styles = useInfrastructureConfigStyles()

    const { workspaceUser } = useUser()

    const areaConfigLoading = useSelector(
        (state: RootState) =>
            state.aptConfig.isLoading[GET_AREA_CONFIG_LIST_REQUEST],
    )
    const unitConfigLoading = useSelector(
        (state: RootState) =>
            state.aptConfig.isLoading[GET_UNIT_CONFIG_LIST_REQUEST],
    )
    const inventoryConfigLoading = useSelector(
        (state: RootState) =>
            state.aptConfig.isLoading[GET_INVENTORY_CONFIG_LIST],
    )

    const {
        inventoryConfigList,
        statusGroupList,
        areaConfigList,
        unitConfigList,
        getUnitConfigMap,
        damageConfigList,
    } = useAptConfig({
        inventoryConfigList: true,
        areaConfigList: true,
        unitConfigList: true,
        statusGroupList: true,
        damageConfigList: true,
        cleanUp: true,
    })

    const [searchValue, setSearchValue] = useState('')

    const [modalOpen, setModalOpen] = useState<FormOpenState>(FORM_CLOSED)
    const [tab, setTab] = useState<ConfigSelection>(INVENTORY_CONFIG)
    const [openCostDrawer, setOpenCostDrawer] = useState<
        InventoryConfig | undefined
    >(undefined)
    const [
        openInfrastructureDrawer,
        setOpenInfrastructureDrawer,
    ] = useState<DrawerState>(closedDrawer)

    const [drawerOpen, setDrawerOpen] = useState(closedDrawer)

    const unitConfigMap = getUnitConfigMap()

    const handleChangeTab = (tab: ConfigSelection) => {
        // 1. close the drawers
        setOpenCostDrawer(undefined)
        setOpenInfrastructureDrawer(closedDrawer)
        // 2. close primary drawer
        setDrawerOpen(closedDrawer)

        // 3. close any open modals
        setModalOpen(FORM_CLOSED)

        // set the tab
        setTab(tab)
    }

    const handeInfrastructureDrawerChange = (drawerState: DrawerState) => {
        // 1. close the cost drawer and main drawer
        setOpenCostDrawer(undefined)
        setDrawerOpen(closedDrawer)
        // 2. close any open modals
        setModalOpen(FORM_CLOSED)

        // set the infrastructure drawer open
        setOpenInfrastructureDrawer(drawerState)
    }

    const handleDrawerChange = (drawer?: DrawerState) => {
        // 1. close the cost drawer and infrastructure drawer
        setOpenCostDrawer(undefined)
        setOpenInfrastructureDrawer(closedDrawer)

        // 2. close any open modals
        setModalOpen(FORM_CLOSED)

        // set the drawer
        if (drawer === undefined) {
            setDrawerOpen(closedDrawer)
        } else {
            setDrawerOpen(drawer)
        }
    }

    const handleCostDrawerChange = (
        selectedInvConfig: InventoryConfig | undefined,
    ) => {
        // 1. close primary drawer
        setDrawerOpen(closedDrawer)
        setOpenInfrastructureDrawer(closedDrawer)

        // 2. close any open modals
        setModalOpen(FORM_CLOSED)

        // set the inventory cost drawer
        setOpenCostDrawer(selectedInvConfig)
    }

    return (
        <SideDrawerContainer
            open={
                drawerOpen.formOpen !== FORM_CLOSED ||
                openCostDrawer !== undefined
            }
        >
            <Container style={{ flex: 1, height: 'calc(100vh - 104px)' }}>
                <Container
                    style={{
                        ...styles.columnStyle,
                        marginRight: theme.spacing(1),
                    }}
                >
                    <Tabs
                        style={{ backgroundColor: theme.palette.grey[200] }}
                        value={tab}
                        onChange={(_, v) => {
                            handleChangeTab(v)
                        }}
                    >
                        <Tab label="Inventory types" value={INVENTORY_CONFIG} />
                        <Tab label="Inventory Groups" value={INVENTORY_GROUP} />
                        <Tab label="Area types" value={AREA_CONFIG} />
                        <Tab label="Unit types" value={UNIT_CONFIG} />
                    </Tabs>

                    <Container style={{ alignItems: 'center', height: 50 }}>
                        <TextField
                            value={searchValue}
                            placeholder="Search"
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ width: 300, marginLeft: theme.spacing(1) }}
                        />

                        <div style={{ flex: 1 }} />
                        {tab === AREA_CONFIG && (
                            <>
                                <Tooltip title="Areas must be assigned to a unit for them to appear on your inspection">
                                    <InfoIcon fontSize="small" color="action" />
                                </Tooltip>
                                {isAccountManager(workspaceUser) && (
                                    <Button
                                        variant="contained"
                                        style={{
                                            margin: theme.spacing(3, 0, 3, 2),
                                            backgroundColor: '#008C85',
                                            color: 'white',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setModalOpen(tab)}
                                    >
                                        {`Create New Area Type`}
                                    </Button>
                                )}
                            </>
                        )}

                        {tab === INVENTORY_CONFIG && (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(3, 0, 3, 2),
                                    backgroundColor: '#008C85',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setModalOpen(tab)}
                            >
                                {`+ Add New Item`}
                            </Button>
                        )}

                        {tab === UNIT_CONFIG &&
                            isAccountManager(workspaceUser) && (
                                <>
                                    <Button
                                        variant="contained"
                                        style={{
                                            margin: theme.spacing(3, 0, 3, 2),
                                            backgroundColor: '#008C85',
                                            color: 'white',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            axiosInstance
                                                .post(
                                                    'apt_config/unit_config/bulk_set_shows_on_schedule/',
                                                    {
                                                        shows_on_schedule: true,
                                                    },
                                                )
                                                .then((res) => {
                                                    dispatch(
                                                        setUnitConfigList(
                                                            res.data,
                                                        ),
                                                    )
                                                    toast.success(
                                                        'All show on schedule',
                                                    )
                                                })
                                        }}
                                    >
                                        Set All Shows on Schedule
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            margin: theme.spacing(3, 0, 3, 2),
                                            backgroundColor: '#008C85',
                                            color: 'white',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            axiosInstance
                                                .post(
                                                    'apt_config/unit_config/bulk_set_shows_on_schedule/',
                                                    {
                                                        shows_on_schedule: false,
                                                    },
                                                )
                                                .then((res) => {
                                                    dispatch(
                                                        setUnitConfigList(
                                                            res.data,
                                                        ),
                                                    )
                                                    toast.success(
                                                        'None show on schedule',
                                                    )
                                                })
                                        }}
                                    >
                                        Set None Shows on Schedule
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            margin: theme.spacing(3, 0, 3, 2),
                                            backgroundColor: '#008C85',
                                            color: 'white',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setModalOpen(tab)}
                                    >
                                        Unit Config Mapping
                                    </Button>
                                </>
                            )}
                    </Container>

                    {tab === INVENTORY_CONFIG && (
                        <Panel
                            data={inventoryConfigList?.filter(
                                (invConf) =>
                                    invConf.name
                                        .toLocaleLowerCase()
                                        .includes(
                                            searchValue.toLocaleLowerCase(),
                                        ) ||
                                    invConf.sku
                                        .toLocaleLowerCase()
                                        .includes(
                                            searchValue.toLocaleLowerCase(),
                                        ),
                            )}
                            isLoading={inventoryConfigLoading}
                            renderItem={(item) => (
                                <InventoryConfigRow
                                    key={`INVENTORY_CONFIG_ROW_${item.id}`}
                                    inventoryConfig={item}
                                    selected={
                                        (drawerOpen.formOpen ===
                                            INVENTORY_CONFIG &&
                                            drawerOpen.selected?.id ===
                                                item.id) ||
                                        openCostDrawer?.id === item.id
                                    }
                                    statusGroupList={statusGroupList}
                                    onClickCost={handleCostDrawerChange}
                                    onClickIssue={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen === ISSUE_CONFIG
                                        ) {
                                            handleDrawerChange(closedDrawer)
                                        } else {
                                            handleDrawerChange({
                                                formOpen: ISSUE_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                    onClickTree={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen ===
                                                INVENTORY_CONFIG
                                        ) {
                                            handleDrawerChange(closedDrawer)
                                        } else {
                                            handleDrawerChange({
                                                formOpen: INVENTORY_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                    onClickInfrastructure={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen ===
                                                INVENTORY_CONFIG
                                        ) {
                                            handeInfrastructureDrawerChange(
                                                closedDrawer,
                                            )
                                        } else {
                                            handeInfrastructureDrawerChange({
                                                formOpen: INVENTORY_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                />
                            )}
                        />
                    )}

                    {tab === INVENTORY_GROUP && (
                        <InventoryGroupTabPanel
                            inventoryConfigs={inventoryConfigList}
                            areaConfigs={areaConfigList}
                        />
                    )}

                    {tab === AREA_CONFIG && (
                        <Panel
                            data={areaConfigList?.filter((areaConf) =>
                                areaConf.name
                                    .toLocaleLowerCase()
                                    .includes(searchValue.toLocaleLowerCase()),
                            )}
                            isLoading={areaConfigLoading}
                            renderItem={(item) => (
                                <AreaConfigRow
                                    key={`AREA_CONFIG_ROW_${item.id}`}
                                    areaConfig={item}
                                    selected={
                                        drawerOpen.formOpen === AREA_CONFIG &&
                                        drawerOpen.selected?.id === item.id
                                    }
                                    onClick={(id) => {
                                        if (
                                            id === drawerOpen.selected?.id &&
                                            drawerOpen.formOpen === AREA_CONFIG
                                        ) {
                                            handleDrawerChange(closedDrawer)
                                        } else {
                                            handleDrawerChange({
                                                formOpen: AREA_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                    onClickPlus={(areaConfig) => {
                                        if (
                                            areaConfig.id ===
                                                openInfrastructureDrawer
                                                    .selected?.id &&
                                            openInfrastructureDrawer.formOpen ===
                                                AREA_CONFIG
                                        ) {
                                            handeInfrastructureDrawerChange(
                                                closedDrawer,
                                            )
                                        } else {
                                            handeInfrastructureDrawerChange({
                                                formOpen: AREA_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                />
                            )}
                        />
                    )}

                    {tab === UNIT_CONFIG && (
                        <Panel
                            data={unitConfigList?.filter((unitConf) =>
                                unitConf.name
                                    .toLocaleLowerCase()
                                    .includes(searchValue.toLocaleLowerCase()),
                            )}
                            isLoading={unitConfigLoading}
                            renderItem={(item) => (
                                <UnitConfigRow
                                    key={`UNIT_CONFIG_ROW_${item.id}`}
                                    unitConfig={item}
                                    onClickPlus={(unitConfig) => {
                                        if (
                                            unitConfig.id ===
                                                openInfrastructureDrawer
                                                    .selected?.id &&
                                            openInfrastructureDrawer.formOpen ===
                                                UNIT_CONFIG
                                        ) {
                                            handeInfrastructureDrawerChange(
                                                closedDrawer,
                                            )
                                        } else {
                                            handeInfrastructureDrawerChange({
                                                formOpen: UNIT_CONFIG,
                                                selected: item,
                                            })
                                        }
                                    }}
                                />
                            )}
                        />
                    )}
                </Container>

                <Modal
                    open={modalOpen !== FORM_CLOSED}
                    onClose={() => setModalOpen(FORM_CLOSED)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={modalOpen !== FORM_CLOSED}>
                        <Paper>
                            {modalOpen === INVENTORY_CONFIG && (
                                <CreateInventoryConfigForm
                                    onClose={() => setModalOpen(FORM_CLOSED)}
                                />
                            )}

                            {modalOpen === AREA_CONFIG && (
                                <CreateAreaConfigForm
                                    onClose={() => setModalOpen(FORM_CLOSED)}
                                />
                            )}

                            {modalOpen === UNIT_CONFIG && (
                                <UnitAreaConfigMapForm
                                    unitConfigList={unitConfigList}
                                    areaConfigList={areaConfigList}
                                />
                            )}
                        </Paper>
                    </Slide>
                </Modal>
            </Container>

            <CreateInfrastructureDrawer
                open={openInfrastructureDrawer}
                areaConfigList={areaConfigList}
                unitConfigList={unitConfigList}
                unitConfigMap={unitConfigMap}
                handleDrawerChange={handleDrawerChange}
            />

            <InvAreaConfigDrawer
                handleDrawerChange={handleDrawerChange}
                areaConfigList={areaConfigList}
                inventoryConfigList={inventoryConfigList ?? []}
                open={drawerOpen}
            />

            <InvConfigStatusCostDrawer
                open={openCostDrawer !== undefined}
                onClose={() => handleCostDrawerChange(undefined)}
                invConf={openCostDrawer}
            />

            <IssueOptionsDrawer
                open={drawerOpen}
                handleDrawerChange={handleDrawerChange}
                damageConfigList={damageConfigList ?? []}
            />
        </SideDrawerContainer>
    )
}

interface PanelProps<T> {
    isLoading: boolean
    data?: T[]
    renderItem: (item: T) => JSX.Element | null
}

function Panel<T>(props: PanelProps<T>) {
    const styles = useInfrastructureConfigStyles()

    const content = props.isLoading ? (
        <Container
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
            scrollY
        >
            <CircularProgress
                style={{ alignSelf: 'center', justifySelf: 'center' }}
                size={100}
            />
        </Container>
    ) : (
        <Container style={{ ...styles.columnStyle }}>
            {props.data?.map(props.renderItem)}
        </Container>
    )

    return (
        <Container
            style={{ ...styles.columnStyle, ...styles.panelBorder }}
            scrollY
        >
            {content}
        </Container>
    )
}
