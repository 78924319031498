import { useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers'
import { LeaseAudit } from '../../models'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UploadBatch } from '../../components'
import { PaginatedResponse } from '../../store'
import {
    DocumentBatch,
    DocumentFilter,
    DocumentOccurance,
    EntityClassification,
    EntityValue,
    LeaseAuditStatus,
    PairingSuggestion,
    PairingSuggestionResults,
    PipelineJobStage,
    ResidentDirectoryRentRoll,
    ResidentTermDirectoryBaseDocument,
    PipelineLog,
    ResidentTermDirectoryDetail,
    SuggestedPair,
    UnpairedResidentTermDirectoryResponse,
    DocumentType,
} from '../../models/DocumentAudit/types'

export const useLeaseAudit = () => {
    const [leaseAudits, setLeaseAudits] = useState<LeaseAudit[]>([])
    const [leaseAudit, setLeaseAudit] = useState<LeaseAudit>()
    const [documentBatches, setDocumentBatches] = useState<DocumentBatch[]>([])
    const [analysisData, setAnalysisData] = useState<any>(null)
    const [pipelineLogs, setPipelineLogs] = useState<PipelineLog[]>([])

    const [
        documentOccurances,
        setDocumentOccurances,
    ] = useState<PaginatedResponse<DocumentOccurance> | null>(null)
    const [
        leaseDocumentOccurances,
        setLeaseDocumentOccurances,
    ] = useState<PaginatedResponse<DocumentOccurance> | null>(null)
    const [
        rentRollDocumentOccurances,
        setRentRollDocumentOccurances,
    ] = useState<PaginatedResponse<DocumentOccurance> | null>(null)

    const [uploadBatch, setUploadBatch] = useState<UploadBatch[]>([])
    const [currentFileIndex, setCurrentFileIndex] = useState(0)

    const [
        residentTermDirectories,
        setResidentTermDirectories,
    ] = useState<PaginatedResponse<ResidentTermDirectoryDetail> | null>(null)

    const [
        unpairedResidentTermDirectories,
        setUnpairedResidentTermDirectories,
    ] = useState<UnpairedResidentTermDirectoryResponse | null>(null)

    const [
        pairingSuggestions,
        setPairingSuggestions,
    ] = useState<PairingSuggestion | null>(null)

    const [
        entityValueFilterSuggestions,
        setEntityValueFilterSuggestions,
    ] = useState<EntityValue[]>([])

    const [rentRolls, setRentRolls] = useState<ResidentDirectoryRentRoll[]>([])

    const [loading, setLoading] = useState({
        createLeaseAudit: false,
        updateLeaseAudit: false,
        fetchLeaseAudit: false,
        fetchDocumentBatches: false,
        uploadLeaseContractBatch: false,
        fetchDocumentOccurances: false,
        fetchRentRollDocumentOccurances: false,
        fetchResidentTermDirectories: false,
        fetchEntityValueFilterSuggestions: false,
        fetchAnalysisData: false,
        exportResidentTermDirectories: false,
        setCriteriaGroup: false,
        fetchUnpairedResidentTermDirectories: false,
        mergeResidentTermDirectories: false,
        fetchPairingSuggestions: false,
        applyPairingSuggestions: false,
        fetchRentRolls: false,
    })

    useEffect(() => {
        const fetchLeaseAudits = async () => {
            const res = await axiosInstance.get('document-audit/')
            setLeaseAudits(res.data)
        }
        fetchLeaseAudits()

        return () => {
            setLeaseAudits([])
            setLeaseAudit(undefined)
            setDocumentBatches([])
            setLeaseDocumentOccurances(null)
            setRentRollDocumentOccurances(null)
        }
    }, [])

    const createLeaseAudit = async (
        name: string,
        leaseLink: string,
        rentRollLink: string,
    ) => {
        setLoading({ ...loading, createLeaseAudit: true })
        const res = await axiosInstance
            .post('document-audit/', {
                name,
                lease_contract_link: leaseLink,
                rent_roll_link: rentRollLink,
            })
            .catch((err) => {
                console.log(err)
            })

        if (res) {
            setLeaseAudits([...leaseAudits, res.data])
        }

        setLoading({ ...loading, createLeaseAudit: false })
    }

    const updateLeaseAudit = async (
        id: number,
        requestBody: {
            status?: LeaseAuditStatus
            name?: string
        },
    ) => {
        setLoading({ ...loading, updateLeaseAudit: true })
        const res = await axiosInstance
            .patch(`document-audit/${id}/`, requestBody)
            .catch((err) => {
                return err
            })
        setLoading({ ...loading, updateLeaseAudit: false })

        if (res instanceof Error) {
            toast.error('Error updating lease audit')
            return null
        }

        setLeaseAudit(res.data)
        return res.data
    }

    const fetchLeaseAudit = async (id: number) => {
        setLoading({ ...loading, fetchLeaseAudit: true })
        const res = await axiosInstance
            .get(`document-audit/${id}`)
            .catch((err) => {
                console.log(err)
            })

        setLoading({ ...loading, fetchLeaseAudit: false })

        if (res) {
            const leaseAudit: LeaseAudit = res.data
            setLeaseAudit(leaseAudit)
            return leaseAudit
        }
        return null
    }

    const fetchLeaseDocumentOccurances = async (
        leaseAuditId: number,
        offset: number = 0,
        limit: number = 25,
        filter?: {
            pipeline_status?: number
            stage_status?: number
            stage?: number
        },
    ) => {
        setLoading({ ...loading, fetchDocumentOccurances: true })
        try {
            const res = await axiosInstance.get(
                `document-audit/document-occurance/`,
                {
                    params: {
                        lease_audit_id: leaseAuditId,
                        offset: offset,
                        limit: limit,
                        document_type: 'PDF',
                        ...filter,
                    },
                },
            )
            setLeaseDocumentOccurances(res.data)
            return res.data
        } catch (error) {
            toast.error('Error fetching document occurrences')
            return null
        } finally {
            setLoading({ ...loading, fetchDocumentOccurances: false })
        }
    }

    const fetchRentRollDocumentOccurances = async (
        leaseAuditId: number,
        offset: number = 0,
        limit: number = 25,
        filter?: {
            pipeline_status?: number
        },
    ) => {
        setLoading({ ...loading, fetchRentRollDocumentOccurances: true })
        try {
            const res = await axiosInstance.get(
                `document-audit/document-occurance/`,
                {
                    params: {
                        lease_audit_id: leaseAuditId,
                        offset: offset,
                        limit: limit,
                        document_type: 'EXCEL',
                        ...filter,
                    },
                },
            )
            setRentRollDocumentOccurances(res.data)
            return res.data
        } catch (error) {
            toast.error('Error fetching document occurrences')
            return null
        } finally {
            setLoading({ ...loading, fetchRentRollDocumentOccurances: false })
        }
    }

    const fetchRentRolls = async (leaseAuditId: number) => {
        setLoading({ ...loading, fetchRentRolls: true })
        try {
            const res = await axiosInstance.get(
                `document-audit/resident-directory/get_rent_rolls/`,
                {
                    params: {
                        lease_audit_id: leaseAuditId,
                    },
                },
            )
            setRentRolls(res.data)
            return res.data
        } catch (error) {
            toast.error('Error fetching rent rolls')
            return null
        } finally {
            setLoading({ ...loading, fetchRentRolls: false })
        }
    }

    const fetchDocumentBatches = async (leaseAuditId: number) => {
        setLoading({ ...loading, fetchDocumentBatches: true })
        const res = await axiosInstance
            .get(`document-audit/document-batch/`, {
                params: {
                    lease_audit_id: leaseAuditId,
                },
            })
            .catch((err) => {
                console.log(err)
            })

        setLoading({ ...loading, fetchDocumentBatches: false })
        if (res) {
            setDocumentBatches(res.data)
            return res.data
        }

        return null
    }

    const detectDocumentType = (files: File[]) => {
        const isPDF = files.every(
            (file) =>
                file.type === 'application/pdf' ||
                file.name.toLowerCase().endsWith('.pdf'),
        )
        const isExcel = files.every(
            (file) =>
                file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type === 'application/vnd.ms-excel' ||
                file.name.toLowerCase().endsWith('.xlsx') ||
                file.name.toLowerCase().endsWith('.xls'),
        )

        if (isPDF) return 'PDF'
        if (isExcel) return 'EXCEL'
        return null
    }

    const uploadLeaseContractBatch = async (
        files: File[],
        leaseAuditId: number,
    ) => {
        setLoading({ ...loading, uploadLeaseContractBatch: true })
        const documentType = detectDocumentType(files)
        if (!documentType) {
            toast.error('Please upload only PDF or Excel files')
            setLoading({ ...loading, uploadLeaseContractBatch: false })
            return null
        }

        const fileHashes = await Promise.all(files.map(hashfile))

        const fileMap: { [key: string]: File } = {}

        const fileDetails = files.map((file, idx) => {
            const fileHash = fileHashes[idx]

            fileMap[fileHash] = file
            return {
                name: file.name,
                key: fileHash,
                document_type: documentType,
            }
        })

        const res = await axiosInstance
            .post('document-audit/document-batch/', {
                file_details: fileDetails,
                lease_audit_id: leaseAuditId,
            })
            .catch((e) => {
                toast.error('Error uploading lease contract batch')
                return e
            })

        if (res.status === 204) {
            toast.success('No new documents found')
            return null
        }

        setLoading({ ...loading, uploadLeaseContractBatch: false })
        if (res instanceof Error) {
            toast.error('Error uploading lease contract batch')
            return null
        }

        const batch: DocumentBatch = res.data
        setDocumentBatches([...documentBatches, batch])

        const createdDocOccurances = batch.document_occurances

        const newDocumentOccurances: PaginatedResponse<DocumentOccurance> = {
            results: [
                ...(documentOccurances?.results || []),
                ...createdDocOccurances,
            ],
            count:
                (documentOccurances?.count || 0) + createdDocOccurances.length,
        }
        setDocumentOccurances(newDocumentOccurances)

        // Set the upload batch

        const uploadBatchJob = createdDocOccurances.map(
            (docOccurance: DocumentOccurance) => ({
                file: fileMap[docOccurance.document.file_sha_256],
                presignedUrl: docOccurance.presigned_url || '',
            }),
        )

        setUploadBatch(uploadBatchJob)

        setCurrentFileIndex(0)

        for (const fileDetail of uploadBatchJob) {
            await axios
                .put(fileDetail.presignedUrl, fileDetail.file, {
                    headers: {
                        'Content-Type': fileDetail.file.type,
                    },
                })
                .catch((e) => {
                    console.log('Error uploading file', e)
                })
            console.log('Uploaded file', fileDetail.file.name)

            setCurrentFileIndex((prev) => prev + 1)
        }

        return batch
    }

    const fetchResidentTermDirectories = async (
        leaseAuditId: number,
        offset: number = 0,
        limit: number = 25,
        documentFilter: DocumentFilter,
        filters?: ResidentTermDirectoryFilters,
    ) => {
        setLoading({ ...loading, fetchResidentTermDirectories: true })

        const res = await axiosInstance

            .post('document-audit/resident-directory/search/', filters, {
                params: {
                    lease_audit_id: leaseAuditId,
                    offset: offset,
                    limit: limit,
                    document_filter:
                        documentFilter === 'all' ? undefined : documentFilter,
                },
            })
            .catch((e) => {
                return e
            })

        setLoading({ ...loading, fetchResidentTermDirectories: false })

        if (res instanceof Error) {
            toast.error('Error fetching resident term directories')
            return null
        }

        const response = res.data
        setResidentTermDirectories(response)
        return response
    }

    const downloadResidentTermDirectories = async (
        leaseAuditId: number,
        leaseAuditName: string,
        offset: number = 0,
        limit: number = 25,
        documentFilter: DocumentFilter,
        filters?: ResidentTermDirectoryFilters,
    ) => {
        setLoading({ ...loading, exportResidentTermDirectories: true })

        const res = await axiosInstance
            .post('document-audit/resident-directory/export/', filters, {
                params: {
                    lease_audit_id: leaseAuditId,
                    offset: offset,
                    limit: limit,
                    document_filter: documentFilter,
                },
                // responseType: 'blob', // Ensure the response is a blob
            })
            .catch((e) => {
                return e
            })

        setLoading({ ...loading, exportResidentTermDirectories: false })

        if (res instanceof Error) {
            toast.error('Error exporting resident term directories')
            return null
        }

        toast.success(
            'We are processing your report. It will be emailed to you shortly.',
        )

        return res.data
    }

    const fetchEntityValueFilterSuggestions = async (
        value: string,
        leaseAuditId: number,
        classification: EntityClassification,
    ) => {
        setLoading({ ...loading, fetchEntityValueFilterSuggestions: true })
        const res = await axiosInstance
            .get(
                `document-audit/${leaseAuditId}/entity_value_filter_suggestions/`,
                {
                    params: {
                        classification: classification,
                        value: value,
                    },
                },
            )
            .catch((e) => {
                return e
            })
        setLoading({ ...loading, fetchEntityValueFilterSuggestions: false })
        if (res instanceof Error) {
            toast.error('Error fetching entity value filter suggestions')
            return null
        }

        setEntityValueFilterSuggestions(res.data)

        return res.data
    }

    const clearEntityValueFilterSuggestions = () => {
        setEntityValueFilterSuggestions([])
    }

    const fetchAnalysisData = async (leaseAuditId: number) => {
        setLoading({ ...loading, fetchAnalysisData: true })
        const res = await axiosInstance
            .get('document-audit/analysis/', {
                params: {
                    lease_audit_id: leaseAuditId,
                },
            })
            .catch((e) => {
                return e
            })

        if (res instanceof Error) {
            toast.error('Error fetching analysis data')
            return null
        }

        setAnalysisData(res.data)
        setLoading({ ...loading, fetchAnalysisData: false })
    }

    const runTickets = async (
        leaseAuditId: number,
        document_type: DocumentType,
        documentOccuranceIds?: number[],
        pipelineStatus?: number,
    ) => {
        const res = await axiosInstance
            .post(
                `document-audit/document-occurance/start_tickets/`,
                {
                    document_occurance_ids: documentOccuranceIds,
                },
                {
                    params: {
                        document_type: document_type,
                        lease_audit_id: leaseAuditId,
                        pipeline_status: pipelineStatus,
                    },
                },
            )
            .catch((e) => {
                return e
            })

        if (res instanceof Error) {
            toast.error('Error running tickets')
            return null
        }

        toast.success('Tickets started')

        return res.data
    }

    const addStagesToTickets = async (
        leaseAuditId: number,
        stages: PipelineJobStage[],
        limit: number = 25,
        offset: number = 0,
        documentOccuranceIds?: number[],
        pipelineStatus?: number,
    ) => {
        const res = await axiosInstance
            .post(
                `document-audit/document-occurance/add_stages/`,
                {
                    document_occurance_ids: documentOccuranceIds,
                    stages: stages,
                },
                {
                    params: {
                        lease_audit_id: leaseAuditId,
                        pipeline_status: pipelineStatus,
                        offset: offset,
                        limit: limit,
                    },
                },
            )
            .catch((e) => {
                return e
            })

        if (res instanceof Error) {
            toast.error('Error adding stages to tickets')
            return null
        }

        toast.success('Adding stages to tickets')

        return res.data
    }

    const assignNewTickets = async (
        leaseAuditId: number,
        documentOccuranceIds?: number[],
        pipelineStatus?: number,
        pipelineStage?: number,
    ) => {
        const res = await axiosInstance
            .post(`document-audit/document-occurance/assign_new_tickets/`, {
                lease_audit_id: leaseAuditId,
                document_occurance_ids: documentOccuranceIds,
                pipeline_status: pipelineStatus,
                pipeline_stage: pipelineStage,
            })
            .catch((e) => {
                return e
            })

        if (res instanceof Error) {
            toast.error('Error assigning new tickets')
            return null
        }

        toast.success('Tickets assigned')

        return res.data
    }

    const setCriteriaGroup = async (
        criteriaGroupId: number,
        leaseAuditId: number,
    ) => {
        setLoading({ ...loading, setCriteriaGroup: true })
        const res = await axiosInstance
            .post(`document-audit/${leaseAuditId}/set_criteria_group/`, {
                criteria_group_id: criteriaGroupId,
            })
            .catch((e) => {
                return e
            })
        setLoading({ ...loading, setCriteriaGroup: false })
        if (res instanceof Error) {
            toast.error('Error setting criteria group')
            return null
        }

        toast.success('Criteria group set')

        setLeaseAudit(res.data)
        return res.data
    }

    const fetchUnpairedResidentTermDirectories = async (
        leaseAuditId: number,
        filters?: ResidentTermDirectoryFilters,
    ) => {
        setLoading({ ...loading, fetchUnpairedResidentTermDirectories: true })
        const res = await axiosInstance
            .post(
                `document-audit/resident-directory/get_unpaired_rent_rolls/`,
                {
                    ...filters,
                },
                {
                    params: {
                        lease_audit_id: leaseAuditId,
                        document_filter: 'unpaired',
                    },
                },
            )
            .catch((e) => {
                return e
            })

        setLoading({ ...loading, fetchUnpairedResidentTermDirectories: false })

        if (res instanceof Error) {
            toast.error('Error fetching unpaired resident term directories')
            return null
        }

        const unpairedResidentTermDirectories = res.data as UnpairedResidentTermDirectoryResponse
        setUnpairedResidentTermDirectories(unpairedResidentTermDirectories)

        return unpairedResidentTermDirectories
    }

    const mergeResidentTermDirectories = async (
        keepId: number,
        removeId: number,
    ) => {
        setLoading({ ...loading, mergeResidentTermDirectories: true })
        const res = await axiosInstance
            .post(
                `document-audit/resident-directory/merge_resident_term_directories/`,
                {
                    keep_id: keepId,
                    remove_id: removeId,
                },
            )
            .catch((e) => {
                return e
            })

        if (res instanceof Error) {
            toast.error('Error merging resident term directories')
            return null
        }

        const rtd = res.data as ResidentTermDirectoryDetail

        if (unpairedResidentTermDirectories === null) {
            return rtd
        }

        const allMergedIds = [keepId, removeId]

        const newUpairedRtds: UnpairedResidentTermDirectoryResponse = {
            ...unpairedResidentTermDirectories,
            unpaired_leases: unpairedResidentTermDirectories.unpaired_leases.filter(
                (lease) => !allMergedIds.includes(lease.id),
            ),
            unpaired_rent_rolls: unpairedResidentTermDirectories.unpaired_rent_rolls.filter(
                (rentRoll) => !allMergedIds.includes(rentRoll.id),
            ),
        }

        setUnpairedResidentTermDirectories(newUpairedRtds)

        setLoading({ ...loading, mergeResidentTermDirectories: false })

        return rtd
    }

    const resetFailedTickets = async (
        leaseAuditId: number,
        documentType: DocumentType,
        documentOccuranceIds?: number[],
        pipelineStatus?: number,
        pipelineStage?: number,
    ) => {
        const res = await axiosInstance
            .post(`document-audit/document-occurance/reset_failed_tickets/`, {
                lease_audit_id: leaseAuditId,
                document_type: documentType,
                document_occurance_ids: documentOccuranceIds,
                pipeline_status: pipelineStatus,
                pipeline_stage: pipelineStage,
            })
            .catch((e) => {
                return e
            })
        

        if (res instanceof Error) {
            toast.error('Error resetting failed tickets')
            return null
        }

        toast.success('Failed tickets reset')

        return res.data
    }

    const updateEntityValue = async (entityValueId: number, value: string) => {
        const res = await axiosInstance.post(
            `document-audit/update-entity-value/`,
            {
                entity_value_id: entityValueId,
                value: value,
            },
        )
        if (res instanceof Error) {
            toast.error('Error updating entity value')
            return null
        }
        toast.success('Entity value updated')

        setResidentTermDirectories((prev) => {
            if (!prev) return prev
            return {
                ...prev,
                documents: prev.results.map((rtd) => {
                    return {
                        ...rtd,
                        entity_values: rtd.documents.map((doc) => ({
                            ...doc,
                            entity_values: doc.entity_values.map((ev) =>
                                ev.id === entityValueId ? { ...ev, value } : ev,
                            ),
                        })),
                    }
                }),
            }
        })

        return res.data
    }

    const fetchPairingSuggestions = async (leaseAuditId: number) => {
        setLoading({ ...loading, fetchPairingSuggestions: true })
        const res = await axiosInstance
            .get(`document-audit/pairing-suggestion/`, {
                params: {
                    lease_audit_id: leaseAuditId,
                },
            })
            .catch((e) => {
                return e
            })

        setLoading({ ...loading, fetchPairingSuggestions: false })

        if (res instanceof Error) {
            toast.error('Error fetching pairing suggestions')
            return null
        }
        setPairingSuggestions(res.data)
        return res.data
    }

    const applyPairingSuggestions = async (
        suggestions: PairingSuggestionResults[],
    ) => {
        setLoading({ ...loading, applyPairingSuggestions: true })
        const res = await axiosInstance
            .post(
                `document-audit/pairing-suggestion/apply_pairing_suggestions/`,
                { suggestions: suggestions },
            )
            .catch((e) => {
                return e
            })

        setLoading({ ...loading, applyPairingSuggestions: false })

        if (res instanceof Error) {
            toast.error('Error applying pairing suggestions')
            return null
        }
        toast.success('Pairing suggestions applied')
        return res.data
    }

    const fetchPipelineLogs = async (documentId?: number, pageId?: number) => {
        const res = await axiosInstance.get(`document-audit/pipeline-log/`, {
            params: {
                document_id: documentId,
                page_id: pageId,
            },
        })

        if (res instanceof Error) {
            toast.error('Error fetching pipeline logs')
            return null
        }

        setPipelineLogs(res.data)
        return res.data
    }

    const runRentRollPipeline = async (leaseAuditId: number) => {
        const res = await axiosInstance.post(`document-audit/run-rent-roll-pipeline/`, {
            lease_audit_id: leaseAuditId,
        })
    }

    return {
        leaseAudits,
        leaseAudit,
        documentBatches,
        fetchLeaseAudit,
        fetchDocumentBatches,
        uploadLeaseContractBatch,
        createLeaseAudit,
        loading,
        uploadBatch,
        currentFileIndex,
        fetchLeaseDocumentOccurances,
        fetchRentRollDocumentOccurances,
        leaseDocumentOccurances,
        rentRollDocumentOccurances,
        fetchResidentTermDirectories,
        residentTermDirectories,
        fetchEntityValueFilterSuggestions,
        entityValueFilterSuggestions,
        clearEntityValueFilterSuggestions,
        fetchAnalysisData,
        analysisData,
        downloadResidentTermDirectories,
        runTickets,
        addStagesToTickets,
        setCriteriaGroup,
        fetchUnpairedResidentTermDirectories,
        unpairedResidentTermDirectories,
        mergeResidentTermDirectories,
        resetFailedTickets,
        assignNewTickets,
        updateEntityValue,
        updateLeaseAudit,
        fetchPairingSuggestions,
        pairingSuggestions,
        fetchRentRolls,
        rentRolls,
        applyPairingSuggestions,
        fetchPipelineLogs,
        pipelineLogs,
    }
}

export type LeaseAuditController = ReturnType<typeof useLeaseAudit>

// Source: https://stackoverflow.com/questions/60595630/javascript-use-input-type-file-to-compute-sha256-file-hash
const hashfile = async (file: File) => {
    // Read the file into an ArrayBuffer
    const arrayBuffer = await file.arrayBuffer()

    // Convert the ArrayBuffer into a an unsigned 8-bit integer array
    const uint8Array = new Uint8Array(arrayBuffer)

    // hash the uint8Array using the SHA-256 algorithm
    const sha256Hash = await window.crypto.subtle.digest('SHA-256', uint8Array)

    // Convert the hash into a hexadecimal
    const result = new Uint8Array(sha256Hash)
    const resultHex = Uint8ArrayToHexString(result)

    return resultHex
}

const Uint8ArrayToHexString = (ui8array: Uint8Array) => {
    let hexstring = ''
    let h = ''
    for (let i = 0; i < ui8array.length; i++) {
        h = ui8array[i].toString(16)
        if (h.length == 1) {
            h = '0' + h
        }
        hexstring += h
    }
    const p = Math.pow(2, Math.ceil(Math.log2(hexstring.length)))
    hexstring = hexstring.padStart(p, '0')
    return hexstring
}

export type ResidentTermDirectoryFilters = {
    [key: string]: {
        has_mismatch: boolean
        custom_filters: {
            operator: string
            value: string | number | boolean
            logic_operator: 'and' | 'or'
        }[]
    }
}
