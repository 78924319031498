import React from 'react'
import { toast } from 'react-toastify'

import moment from 'moment'

import { Badge, Button, IconButton, Tooltip, useTheme } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TuneIcon from '@material-ui/icons/Tune'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
    AccountTree,
    AssignmentIndOutlined,
    BarChart,
} from '@material-ui/icons'

import {
    ALL,
    CONTRACTOR,
    CUSTOM,
    IN_HOUSE,
    JbFormState,
    MY_TEAM,
    NEXT_WEEK,
    THIS_WEEK,
} from './JobBoardHome'
import { Container } from '../../components'
import { WorkSpaceUser } from '../../models'
import { ReactComponent as EZLogo } from '../../assets/Logo.svg'

interface Props {
    dateFilter: number
    startDate: MaterialUiPickersDate
    endDate: MaterialUiPickersDate
    vendorFilter: number
    formState: JbFormState
    failedServiceRequests: number
    ezNowCount: number
    workspaceUser?: WorkSpaceUser
    unassignedWoCount: number
    setDateFilter: React.Dispatch<React.SetStateAction<number>>
    setStartDate: React.Dispatch<React.SetStateAction<MaterialUiPickersDate>>
    setEndDate: React.Dispatch<React.SetStateAction<MaterialUiPickersDate>>
    setVendorFilter: React.Dispatch<React.SetStateAction<number>>
    refreshData: (
        startDate: Date | MaterialUiPickersDate,
        endDate: Date | MaterialUiPickersDate,
    ) => void
    openFilterDrawer: () => void
    setFormState: React.Dispatch<React.SetStateAction<JbFormState>>
}

export const JobBoardHeader = (props: Props) => {
    const {
        dateFilter,
        startDate,
        endDate,
        vendorFilter,
        formState,
        failedServiceRequests,
        ezNowCount,
        workspaceUser,
        unassignedWoCount,
        setDateFilter,
        setStartDate,
        setEndDate,
        setVendorFilter,
        refreshData,
        openFilterDrawer,
        setFormState,
    } = props

    const theme = useTheme()

    const ButtonGroupStyle: React.CSSProperties = {
        textTransform: 'none',
        fontSize: 15,
        fontWeight: 600,
    }

    return (
        <Container style={{ flexWrap: 'wrap' }}>
            <Container style={{ flexDirection: 'column' }}>
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Job Board
                </span>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        color: theme.palette.primary.dark,
                    }}
                >
                    View and manage your vendors work!
                </span>
            </Container>
            <Container
                style={{
                    alignItems: 'center',
                    marginLeft: theme.spacing(2),
                    flex: 1,
                    flexWrap: 'wrap',
                }}
            >
                <Container style={{ marginTop: theme.spacing(1) }}>
                    <ToggleButtonGroup
                        value={dateFilter}
                        aria-label="Date Filter"
                        exclusive
                        style={{
                            border: `1px solid ${theme.palette.primary.dark}`,
                            borderRadius: '5px',
                            height: 40,
                        }}
                    >
                        <Button
                            value={THIS_WEEK}
                            aria-label="This Week"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    dateFilter === THIS_WEEK
                                        ? theme.palette.primary.dark
                                        : '',
                                color: dateFilter === THIS_WEEK ? 'white' : '',
                            }}
                            onClick={() => {
                                setDateFilter(THIS_WEEK)
                                const tempStartDate = moment().day(1).toDate()
                                const tempEndDate = moment().day(7).toDate()
                                setStartDate(tempStartDate)
                                setEndDate(tempEndDate)
                                refreshData(tempStartDate, tempEndDate)
                            }}
                        >
                            This Week
                        </Button>
                        <Button
                            value={NEXT_WEEK}
                            aria-label="Next Week"
                            style={{
                                ...ButtonGroupStyle,
                                borderRight: `1px solid ${theme.palette.primary.dark}`,
                                borderLeft: `1px solid ${theme.palette.primary.dark}`,
                                backgroundColor:
                                    dateFilter === NEXT_WEEK
                                        ? theme.palette.primary.dark
                                        : '',
                                color: dateFilter === NEXT_WEEK ? 'white' : '',
                            }}
                            onClick={() => {
                                setDateFilter(NEXT_WEEK)
                                const tempStartDate = moment().day(8).toDate()
                                const tempEndDate = moment().day(15).toDate()
                                setStartDate(tempStartDate)
                                setEndDate(tempEndDate)
                                refreshData(tempStartDate, tempEndDate)
                            }}
                        >
                            Next Week
                        </Button>
                        <Button
                            value={CUSTOM}
                            aria-label="Custom"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    dateFilter === CUSTOM
                                        ? theme.palette.primary.dark
                                        : '',
                                color: dateFilter === CUSTOM ? 'white' : '',
                            }}
                            onClick={() => setDateFilter(CUSTOM)}
                        >
                            Custom
                        </Button>
                    </ToggleButtonGroup>
                    <KeyboardDatePicker
                        clearable
                        disabled={dateFilter !== CUSTOM}
                        value={startDate}
                        onChange={(date) => {
                            setStartDate(date)
                            if (endDate === null || (date && endDate < date)) {
                                //if the end date is before the start date, set the end date to the same as the start date
                                setEndDate(date)
                            }
                            refreshData(date, endDate)
                        }}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{
                            width: 180,
                            marginLeft: theme.spacing(2),
                        }}
                        label="Start Date"
                        size="small"
                    />
                    <KeyboardDatePicker
                        disabled={dateFilter !== CUSTOM}
                        error={endDate === null}
                        value={endDate}
                        onChange={(date) => {
                            if (date === null) {
                                return setEndDate(null)
                            }
                            if (startDate && date >= startDate) {
                                // if date is LATER than the start date
                                refreshData(startDate, date)
                                return setEndDate(date)
                            }
                            return (
                                startDate &&
                                toast.error(
                                    'End Date must be the day of or after the Start Date',
                                )
                            )
                        }}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        label="End Date"
                        style={{
                            margin: theme.spacing(0, 1),
                            width: 180,
                        }}
                        size="small"
                    />
                </Container>

                <Container style={{ flex: 1 }} />

                <Button
                    variant="contained"
                    style={{
                        marginRight: theme.spacing(1),
                        marginTop: theme.spacing(1),
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        setFormState({
                            ...formState,
                            createWorkorderForm: true,
                        })
                    }
                >
                    + Create Action Item
                </Button>

                <Container
                    style={{
                        marginTop: theme.spacing(1),
                        alignItems: 'center',
                    }}
                >
                    <ToggleButtonGroup
                        value={vendorFilter}
                        aria-label="Vendor Filter"
                        exclusive
                        style={{
                            border: `1px solid ${theme.palette.primary.dark}`,
                            borderRadius: '5px',
                            height: 40,
                        }}
                    >
                        <Button
                            value={ALL}
                            aria-label="All"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    vendorFilter === ALL
                                        ? theme.palette.primary.dark
                                        : '',
                                color: vendorFilter === ALL ? 'white' : '',
                            }}
                            onClick={() => setVendorFilter(ALL)}
                        >
                            All Vendors
                        </Button>
                        <Button
                            value={IN_HOUSE}
                            aria-label="In House"
                            style={{
                                ...ButtonGroupStyle,
                                borderRight: `1px solid ${theme.palette.primary.dark}`,
                                borderLeft: `1px solid ${theme.palette.primary.dark}`,
                                backgroundColor:
                                    vendorFilter === IN_HOUSE
                                        ? theme.palette.primary.dark
                                        : '',
                                color: vendorFilter === IN_HOUSE ? 'white' : '',
                            }}
                            onClick={() => setVendorFilter(IN_HOUSE)}
                        >
                            In House
                        </Button>
                        <Button
                            value={CONTRACTOR}
                            aria-label="Contractor"
                            style={{
                                ...ButtonGroupStyle,
                                backgroundColor:
                                    vendorFilter === CONTRACTOR
                                        ? theme.palette.primary.dark
                                        : '',
                                color:
                                    vendorFilter === CONTRACTOR ? 'white' : '',
                            }}
                            onClick={() => setVendorFilter(CONTRACTOR)}
                        >
                            Contractor
                        </Button>
                        <Button
                            value={MY_TEAM}
                            aria-label="My Team"
                            style={{
                                ...ButtonGroupStyle,
                                borderRight: `1px solid ${theme.palette.primary.dark}`,
                                borderLeft: `1px solid ${theme.palette.primary.dark}`,
                                backgroundColor:
                                    vendorFilter === MY_TEAM
                                        ? theme.palette.primary.dark
                                        : '',
                                color: vendorFilter === MY_TEAM ? 'white' : '',
                            }}
                            onClick={() => setVendorFilter(MY_TEAM)}
                        >
                            My Team
                        </Button>
                    </ToggleButtonGroup>

                    <Tooltip title="Property Analytics">
                        <IconButton
                            style={{ marginLeft: theme.spacing(2) }}
                            onClick={() => {
                                setFormState({
                                    ...formState,
                                    propertyAnalyticsModal: true,
                                })
                            }}
                        >
                            <BarChart />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Vendor Service Rules">
                        <IconButton
                            onClick={() => {
                                setFormState({
                                    ...formState,
                                    vendorRuleModal: true,
                                })
                            }}
                        >
                            <AccountTree />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="EZNow Requests">
                        <IconButton
                            onClick={() => {
                                setFormState({
                                    ...formState,
                                    ezNowModal: true,
                                })
                            }}
                        >
                            <Badge badgeContent={ezNowCount} color="secondary">
                                <EZLogo style={{ height: 30, width: 30 }} />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Pending Work">
                        <IconButton
                            onClick={() => {
                                setFormState({
                                    ...formState,
                                    serviceRequestModal: true,
                                })
                            }}
                        >
                            <Badge
                                badgeContent={
                                    failedServiceRequests + unassignedWoCount
                                }
                                color="secondary"
                            >
                                <AssignmentIndOutlined />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <IconButton onClick={openFilterDrawer}>
                        <TuneIcon />
                    </IconButton>
                </Container>
            </Container>
        </Container>
    )
}
