import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Switch,
    Tab,
    Tabs,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container, SideDrawerContainer } from '../../components'
import {
    useAppDispatch,
    useAptConfig,
    useCompany,
    useSchedule,
    useService,
    useUser,
} from '../../hooks'
import {
    ActionItemInspectionStatusTrigger,
    Frequency,
    INCIDENT_REPORTS,
    Inspection,
    InspectionType,
    TenantNegligibleFilter,
} from '../../models'
import {
    addInspection,
    getInspectionList,
    InventoryInspectionListAxiosReq,
    RootState,
} from '../../store'
import { axiosInstance } from '../../helpers'
import { InspectionFormDrawer } from './InspectionForm'
import { toast } from 'react-toastify'
import { Mail } from '@material-ui/icons'
import { InspectionListTable } from './InspectionListTable'
import { InspectionTypeTable } from './InspectionTypeTable'
import { InspectionTypeDrawer } from './InspectionTypeDrawer'
import { InspectionFrequencyTable } from './InspectionFrequencyTable'
import { InspectionFrequencyDrawer } from './InspectionFrequencyDrawer'
import { InspectionAssignModal } from './InspectionAssignModal'
import InfoIcon from '@material-ui/icons/Info'
import {
    CREATE_FREQUENCY_TAB,
    InspectionConfigurationModal,
} from './InspectionConfiguration/InspectionConfigurationModal'
import { ActionItemTriggerTable } from './ActionItemTriggerTable'
import { ActionItemTriggerDrawer } from './ActionItemTriggerDrawer'
import { time } from 'console'
import { hasPermission } from '../../models/Users/services'
import { IncidentReportRoot } from './IncidentReportRoot'
import { CreateInspectionModal } from './CreateInspectionModal'
import { SearchField } from '../../components/SearchField'
import { useInspectionFrequency } from '../../hooks/useInspectionFrequency'

export const InspectionList = () => {
    const dispatch = useAppDispatch()

    const inspectionList = useSelector(
        (state: RootState) => state.inspection.inspectionList,
    )

    const {
        inspectionTypeList,
        getAreaConfigMap,
        inventoryConfigList,
        customStatusList,
    } = useAptConfig({
        inspectionTypeList: true,
        inventoryConfigList: true,
        areaConfigList: true,
        customStatusList: true,
    })

    const {
        frequencyList,
        setFrequencyList,
        getFrequencyList,
        selectedFrequency,
        setSelectedFrequency,
        toggleFrequencyActive,
    } = useInspectionFrequency()

    const [triggerList, setTriggerList] = useState<
        ActionItemInspectionStatusTrigger[] | null
    >(null)

    const areaConfigMap = getAreaConfigMap()

    const { scheduleList, getScheduleList } = useSchedule()

    const [searchField, setSearchField] = useState('')

    const theme = useTheme()

    const currentYear = new Date().getFullYear()

    const [createInspectionModalOpen, setCreateInspectionModalOpen] = useState(
        false,
    )

    const [selectedTab, setSelectedTab] = useState<number>(INSPECTION_TAB)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedInspection, setSelectedInspection] = useState<
        Inspection | undefined
    >()
    const [selectedType, setSelectedType] = useState<
        InspectionType | undefined
    >()
    const [reportYear, setReportYear] = useState(currentYear)

    const [archivedFilter, setArchivedFilter] = useState(false)

    const [createFrequencyModalOpen, setCreateFrequencyModalOpen] = useState(
        false,
    )

    const [selectedTriggerId, setSelectedTriggerId] = useState(-1)

    const [
        openInspectionConfiguration,
        setOpenInspectionConfiguration,
    ] = useState(false)

    const [incidentReportFilters, setIncidentReportFilters] = useState({
        status: -1,
        incidentType: -1,
        tenantNegligible: TenantNegligibleFilter.NO_FILTER,
    })

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const yearOptions = [currentYear]
    let yearOption = currentYear
    // 2021 because it was the first year of ezturn inspections
    while (yearOption > 2021) {
        yearOption--
        yearOptions.push(yearOption)
    }

    useEffect(() => {
        dispatch(getInspectionList({ params: {} }))
        getScheduleList({ params: { no_inspection: true } })
        getFrequencyList()
    }, [])

    const handleDrawerClose = () => {
        setDrawerOpen(false)
        setSelectedInspection(undefined)
        setSelectedType(undefined)
    }

    const { workspaceUser, userList, getUserList } = useUser()
    const { vendorList, getVendorList } = useCompany()
    const { serviceList, getServiceList } = useService()

    const [moveOutInspection, setMoveOutInspection] = useState<
        number | undefined
    >()

    useEffect(() => {
        if (workspaceUser?.active_workspace.move_out_inspection_config) {
            setMoveOutInspection(
                workspaceUser.active_workspace.move_out_inspection_config,
            )
        }
    }, [])

    const archivedCount =
        inspectionList?.filter((ins) => !ins.active).length ?? 0

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 104px)',
                }}
            >
                {/* Header */}
                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <Container alignItems="center">
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Inspection
                            </span>

                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openPopover(e)
                                }}
                            >
                                <InfoIcon fontSize="small" color="action" />
                            </IconButton>
                        </Container>

                        <Popover
                            open={popOverOpen}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                        >
                            <Container
                                direction="column"
                                style={{
                                    padding: theme.spacing(2),
                                }}
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '16px',
                                    }}
                                >
                                    Steps to create an Inspection
                                </Container>
                                <Container>
                                    Step 1: Create or choose an Inspection Type
                                </Container>
                                <Container>
                                    Step 2: Create an Inspection
                                </Container>
                                <Container>
                                    Step 3: Assign an Inspection
                                </Container>
                            </Container>
                        </Popover>

                        <FormControlLabel
                            control={
                                <Switch
                                    value={archivedFilter}
                                    color="primary"
                                    onChange={() =>
                                        setArchivedFilter(!archivedFilter)
                                    }
                                />
                            }
                            labelPlacement="start"
                            label={`Show Archived (${archivedCount})`}
                            style={{ margin: 0 }}
                        />
                    </Container>

                    <div style={{ flex: 1 }} />
                    <FormControl
                        variant="outlined"
                        style={{ margin: theme.spacing(2) }}
                    >
                        <InputLabel id={`report-year-label`}>
                            Report Year
                        </InputLabel>
                        <Select
                            labelId={`report-year-label`}
                            id={`report-year-select`}
                            value={reportYear}
                            onChange={(e) => {
                                setReportYear(e.target.value as number)
                            }}
                            label="Report Year"
                            style={{ paddingLeft: theme.spacing(1) }}
                        >
                            {yearOptions.map((year) => (
                                <MenuItem
                                    key={`YEAR_SELECT__${year}`}
                                    value={year}
                                >
                                    <span>{year}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title="Email Year Report">
                        <IconButton
                            onClick={() => {
                                const startOfYear = new Date(reportYear, 0)
                                const endOfYear = new Date(
                                    reportYear,
                                    11,
                                    31,
                                    23,
                                    59,
                                    59,
                                    999,
                                )

                                const req: InventoryInspectionListAxiosReq = {
                                    params: {
                                        lower_bound_date: startOfYear.toISOString(),
                                        upper_bound_date: endOfYear.toISOString(),
                                    },
                                }
                                axiosInstance.get(
                                    'inspection/inventory-inspection/create_report/',
                                    req,
                                )
                                toast.success(
                                    'You will receive an email with your report shortly!',
                                )
                            }}
                        >
                            <Mail fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (selectedTab === INSPECTION_TAB) {
                                setCreateInspectionModalOpen(true)
                            } else {
                                setDrawerOpen(true)
                            }
                        }}
                    >
                        {selectedTab === INSPECTION_TAB && `+ Add Inspection`}
                        {selectedTab === INSPECTION_TYPE_TAB &&
                            `+ Add Inspection Type`}
                        {selectedTab === INSPECTION_FREQUENCIES &&
                            '+ Add Inspection Frequency'}
                        {selectedTab === ACTION_ITEM_TRIGGER_TAB &&
                            '+ Add Trigger'}
                        {selectedTab === INCIDENT_REPORT_TAB &&
                            'Incident Filters'}
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenInspectionConfiguration(true)}
                    >
                        + Configure Inspection
                    </Button>
                    <SearchField
                        variant="outlined"
                        style={{
                            margin: theme.spacing(3, 0, 3, 2),
                            backgroundColor: 'white',
                        }}
                        value={searchField}
                        onChange={(e) => {
                            setSearchField(e.target.value)
                        }}
                        placeholder="Inspection Name"
                    />
                </Container>
                {/* End Header */}
                <Tabs
                    style={{
                        width: '100%',
                        backgroundColor: theme.palette.grey[300],
                    }}
                    value={selectedTab}
                    onChange={(_, v) => {
                        if (v !== selectedTab) {
                            if (
                                v === ACTION_ITEM_TRIGGER_TAB &&
                                triggerList === null
                            ) {
                                // Load the trigger specific net workrequests if they have not been loaded
                                axiosInstance
                                    .get(
                                        'apt_config/action-item-inspection-status-trigger/',
                                    )
                                    .then((res) => {
                                        setTriggerList(res.data)
                                    })
                                getUserList({ params: { my_team: true } })
                                getVendorList({ params: { my_team: true } })
                                getServiceList({})
                            }

                            setSelectedTab(v)
                            handleDrawerClose()
                        }
                    }}
                >
                    <Tab
                        label="Inspections"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Inspection Types"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Inspection Frequencies"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Action Item Triggers"
                        style={{ textTransform: 'none' }}
                    />
                    {hasPermission(workspaceUser, INCIDENT_REPORTS) && (
                        <Tab
                            label="Incident Reports"
                            style={{ textTransform: 'none' }}
                        />
                    )}
                </Tabs>

                {selectedTab === INSPECTION_TAB && (
                    <>
                        <InspectionListTable
                            inspectionList={inspectionList?.filter(
                                (inspection) =>
                                    inspection.active !== archivedFilter &&
                                    (searchField.trim() === '' ||
                                        inspection.name
                                            .toLowerCase()
                                            .includes(
                                                searchField.toLowerCase(),
                                            )),
                            )}
                            selectedInspection={selectedInspection}
                            setSelectedInspection={setSelectedInspection}
                            setDrawerOpen={setDrawerOpen}
                            active={archivedFilter === false}
                        />
                        <InspectionFormDrawer
                            open={drawerOpen}
                            handleClose={() => {
                                handleDrawerClose()
                            }}
                            inspectionTypeList={inspectionTypeList ?? []}
                            scheduleList={scheduleList ?? []}
                            getScheduleList={getScheduleList}
                            selectedInspection={selectedInspection}
                        />
                    </>
                )}

                {selectedTab === INSPECTION_TYPE_TAB && (
                    <Container direction="column">
                        <Container
                            style={{
                                marginTop: theme.spacing(2),
                            }}
                        >
                            Inspection types are templates of the items you want
                            to inspect for an inspection.
                        </Container>
                        <InspectionTypeTable
                            inspectionTypeList={inspectionTypeList}
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            setDrawerOpen={setOpenInspectionConfiguration}
                            moveOutInspection={moveOutInspection}
                        />
                        <InspectionTypeDrawer
                            handleClose={() => handleDrawerClose()}
                            open={drawerOpen}
                            inspectionType={selectedType}
                            setMoveOutInspection={setMoveOutInspection}
                        />
                    </Container>
                )}

                {selectedTab === INSPECTION_FREQUENCIES && (
                    <>
                        <InspectionFrequencyTable
                            frequencyList={frequencyList}
                            setFrequencyModalOpenViewOnly={(freq) => {
                                setCreateFrequencyModalOpen(true)
                                setSelectedFrequency(freq)
                            }}
                            toggleFrequencyActive={toggleFrequencyActive}
                        />
                        <InspectionFrequencyDrawer
                            open={drawerOpen}
                            handleClose={() => handleDrawerClose()}
                            setCreateModalOpen={() =>
                                setCreateFrequencyModalOpen(true)
                            }
                        />
                    </>
                )}

                {selectedTab === ACTION_ITEM_TRIGGER_TAB && (
                    <>
                        <ActionItemTriggerTable
                            triggerList={triggerList}
                            selectedTriggerId={selectedTriggerId}
                            onSelectTrigger={(id) => {
                                setSelectedTriggerId(id)
                                setDrawerOpen(true)
                            }}
                        />
                        <ActionItemTriggerDrawer
                            trigger={triggerList?.find(
                                (t) => t.id === selectedTriggerId,
                            )}
                            open={drawerOpen}
                            handleClose={() => {
                                setSelectedTriggerId(-1)
                                handleDrawerClose()
                            }}
                            userList={userList}
                            vendorList={vendorList}
                            serviceList={serviceList}
                            inventoryConfigList={inventoryConfigList ?? []}
                            customStatusList={customStatusList}
                            handleDelete={(trigger) => {
                                axiosInstance
                                    .delete(
                                        `apt_config/action-item-inspection-status-trigger/${trigger.id}/`,
                                    )
                                    .then(() => {
                                        setTriggerList((oldList) => {
                                            if (oldList === null) {
                                                return oldList
                                            }

                                            return oldList.filter(
                                                (t) => t.id !== trigger.id,
                                            )
                                        })
                                        toast.success(
                                            `${trigger.title} Deleted`,
                                            // { position: 'bottom-center' },
                                        )
                                    })
                                    .catch(() => {
                                        toast.error(
                                            'There was a problem deleting the trigger',
                                            // { position: 'bottom-center' },
                                        )
                                    })
                            }}
                            handleSave={(trigger) => {
                                let newTriggerList = [...(triggerList ?? [])]
                                if (selectedTriggerId !== -1) {
                                    // Handle Update
                                    newTriggerList = newTriggerList.map(
                                        (existingTrigger) => {
                                            if (
                                                existingTrigger.id ===
                                                trigger.id
                                            ) {
                                                return trigger
                                            }
                                            return existingTrigger
                                        },
                                    )
                                } else {
                                    console.log('add!')
                                    // Handle save new trigger
                                    newTriggerList.push(trigger)
                                }
                                setTriggerList(newTriggerList)
                            }}
                        />
                    </>
                )}
                {selectedTab === INCIDENT_REPORT_TAB && (
                    <>
                        <IncidentReportRoot
                            incidentReportFilters={incidentReportFilters}
                            onIncidentSelect={() => {
                                setDrawerOpen(true)
                            }}
                            setFilters={setIncidentReportFilters}
                            filtersOpen={drawerOpen}
                            handleFiltersClose={() => {
                                handleDrawerClose()
                            }}
                        />
                    </>
                )}
            </Container>

            <CreateInspectionModal
                open={createInspectionModalOpen}
                scheduleList={scheduleList}
                inspectionTypeList={inspectionTypeList ?? []}
                userList={userList}
                onClose={() => setCreateInspectionModalOpen(false)}
            />

            <InspectionAssignModal
                makeFilteredRequest={() => {}}
                open={createFrequencyModalOpen}
                areaConfigMap={areaConfigMap}
                onClose={() => {
                    setCreateFrequencyModalOpen(false)
                    setSelectedFrequency(null)
                }}
                onFrequencyCreate={(freq) => {
                    if (freq.inspection) {
                        dispatch(addInspection(freq.inspection))
                    }
                    setFrequencyList([...frequencyList, freq])
                }}
                onFrequencyDelete={(freq) => {
                    const newList = frequencyList.reduce<Frequency[]>(
                        (prev, frequency) => {
                            if (frequency.id === freq.id) {
                                return prev
                            }
                            return prev.concat(frequency)
                        },
                        [],
                    )
                    setFrequencyList(newList)
                }}
                viewOnlyMode={selectedFrequency ?? undefined}
            />

            <InspectionConfigurationModal
                open={openInspectionConfiguration}
                handleClose={() => {
                    setOpenInspectionConfiguration(false)
                    setSelectedType(undefined)
                }}
                inspectionTypeList={inspectionTypeList ?? []}
                scheduleList={scheduleList}
                getScheduleList={getScheduleList}
                frequencyList={frequencyList}
                setFrequencyList={setFrequencyList}
                inspectionType={selectedType}
                setMoveOutInspection={setMoveOutInspection}
            />
        </SideDrawerContainer>
    )
}

const INSPECTION_TAB = 0
const INSPECTION_TYPE_TAB = 1
const INSPECTION_FREQUENCIES = 2
const ACTION_ITEM_TRIGGER_TAB = 3
const INCIDENT_REPORT_TAB = 4
