import React, { useEffect, useState } from 'react'
import styles from './LeaseAudit.module.css'

import FilterListIcon from '@material-ui/icons/FilterList'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import MergeTypeIcon from '@material-ui/icons/MergeType'

import {
    LeaseAuditController,
    ResidentTermDirectoryFilters,
    CriteriaGroupController,
} from '../../../hooks/documentAudit'

import {
    Theme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Select,
    MenuItem,
    CircularProgress,
    Tooltip,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { Container } from '../../../components'

import { Pagination } from '@material-ui/lab'
import { usePopover } from '../../../hooks/usePopover'

import {
    EntityValue,
    EntityClassification,
    ResidentTermDirectoryDetail,
    getEntityClassificationUiName,
    orderedClassifications,
    RentRollAccountingMode,
} from '../../../models'

import { DocumentFilter, MatchType } from '../../../models/DocumentAudit/types'
import { FilterPopover, FilterState } from '../Filter'
import { ResidentDirectoryDetailModal } from '../ResidentDirectoryDetailModal'
import { CriteriaGroupModal } from '../CriteriaGroupModal/CriteriaGroupModal'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    criteriaGroupController: CriteriaGroupController
    mode: 'paired' | 'all'
}

export const TableTab = (props: Props) => {
    const {
        theme,
        height,
        leaseAuditController,
        criteriaGroupController,
        mode,
    } = props
    const {
        criteriaGroups,
        createCriteriaGroup,
        updateCriteriaGroup,
    } = criteriaGroupController

    const [criteriaGroupModalOpen, setCriteriaGroupModalOpen] = useState({
        open: false,
        groupId: -1,
    })

    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)

    const filterPopoverState = usePopover<EntityClassification>()

    const [filterState, setFilterState] = useState<FilterState>({})

    const documentFilter = mode === 'paired' ? 'paired' : 'all'
    const rentRollAccountingMode = 'RENT_ROLL_SCHEDULED'

    const [
        selectedEntityValueMap,
        setSelectedEntityValueMap,
    ] = useState<DirectoryEntityValueMap | null>(null)
    const [
        selectedClassification,
        setSelectedClassification,
    ] = useState<EntityClassification | null>(null)
    const [
        selectedEntityValue,
        setSelectedEntityValue,
    ] = useState<EntityValue | null>(null)

    useEffect(() => {
        console.log('fetching resident term directories', documentFilter)
        fetchResidentTermDirectories(limit, offset, filterState, documentFilter)
    }, [leaseAuditController.leaseAudit])

    const fetchResidentTermDirectories = (
        limit: number,
        offset: number,
        filterState: FilterState,
        documentFilter: DocumentFilter,
    ) => {
        if (leaseAuditController.leaseAudit) {
            const filters: ResidentTermDirectoryFilters = {}

            Object.keys(filterState).forEach((key) => {
                const classification = key as EntityClassification
                const filter = filterState[classification]

                if (filter) {
                    filters[classification] = {
                        has_mismatch: filter.hasMismatch,
                        custom_filters: filter.filters.map((filter) => {
                            if (filter.value instanceof Date) {
                                return {
                                    operator: filter.operator,
                                    value: filter.value.toISOString(),
                                    logic_operator: filter.logicOperator,
                                }
                            }
                            return {
                                operator: filter.operator,
                                value: filter.value,
                                logic_operator: filter.logicOperator,
                            }
                        }),
                    }
                }
            })

            leaseAuditController.fetchResidentTermDirectories(
                leaseAuditController.leaseAudit.id,
                offset,
                limit,
                documentFilter,
                filters,
            )
        }
    }

    const downloadResidentTermDirectories = (
        limit: number,
        offset: number,
        filterState: FilterState,
        documentFilter: DocumentFilter,
    ) => {
        if (leaseAuditController.leaseAudit) {
            const filters: ResidentTermDirectoryFilters = {}

            Object.keys(filterState).forEach((key) => {
                const classification = key as EntityClassification
                const filter = filterState[classification]

                if (filter) {
                    filters[classification] = {
                        has_mismatch: filter.hasMismatch,
                        custom_filters: filter.filters.map((filter) => {
                            if (filter.value instanceof Date) {
                                return {
                                    operator: filter.operator,
                                    value: filter.value.toISOString(),
                                    logic_operator: filter.logicOperator,
                                }
                            }
                            return {
                                operator: filter.operator,
                                value: filter.value,
                                logic_operator: filter.logicOperator,
                            }
                        }),
                    }
                }
            })

            leaseAuditController.downloadResidentTermDirectories(
                leaseAuditController.leaseAudit.id,
                leaseAuditController.leaseAudit.name,
                limit,
                offset,
                documentFilter,
                filters,
            )
        }
    }

    const classificationMap: Set<EntityClassification> = new Set()

    leaseAuditController.residentTermDirectories?.results?.forEach((dir) =>
        dir.documents.forEach((doc) =>
            doc.entity_values.forEach((ev) => {
                if (!classificationMap.has(ev.classification)) {
                    classificationMap.add(ev.classification)
                }
            }),
        ),
    )

    let classificationsArray = orderedClassifications.filter((c) =>
        classificationMap.has(c),
    )

    if (classificationsArray.length === 0) {
        classificationsArray = orderedClassifications
    }

    const totalRows = leaseAuditController.residentTermDirectories?.count || 0

    const handleCellSelect = (
        entityValueMap: DirectoryEntityValueMap,
        selectedClassification: EntityClassification,
        firstEntityValue: EntityValue | null,
    ) => {
        setSelectedEntityValueMap(entityValueMap)
        setSelectedClassification(selectedClassification)
        setSelectedEntityValue(firstEntityValue)
    }

    const handleCloseModal = () => {
        setSelectedEntityValueMap(null)
    }

    const activeMatchCriteriaId =
        leaseAuditController.leaseAudit?.criteria_group || -1
    const criteriaGroup = criteriaGroups.find(
        (c) => c.id === activeMatchCriteriaId,
    )

    const matchTypeMap: MatchTypeMap = new Map()
    criteriaGroup?.match_criteria.forEach((c) => {
        matchTypeMap.set(c.entity_classification, c.match_type)
    })

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: height,
            }}
        >
            <TableContainer style={{ flex: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableCell>
                            <span>Match Status</span>
                        </TableCell>
                        {classificationsArray.map((classification) => (
                            <FilterHeadCell
                                key={classification}
                                filterEnabled={
                                    filterState[classification]?.filters
                                        .length > 0
                                }
                                displayString={getEntityClassificationUiName(
                                    classification,
                                )}
                                theme={theme}
                                onClick={(e) => {
                                    leaseAuditController.clearEntityValueFilterSuggestions()

                                    filterPopoverState.handleOpen(
                                        e,
                                        classification,
                                    )
                                }}
                                onClear={() => {
                                    const newFilterState = { ...filterState }
                                    delete newFilterState[classification]
                                    setFilterState(newFilterState)
                                    fetchResidentTermDirectories(
                                        limit,
                                        offset,
                                        newFilterState,
                                        documentFilter,
                                    )
                                }}
                            />
                        ))}
                    </TableHead>
                    <TableBody>
                        {leaseAuditController.loading
                            .fetchResidentTermDirectories ? (
                            <CircularProgress />
                        ) : (
                            leaseAuditController.residentTermDirectories?.results?.map(
                                (dir) => (
                                    <ResidentTermDirectoryRow
                                        key={dir.id}
                                        theme={theme}
                                        dir={dir}
                                        classifications={classificationsArray}
                                        rentRollAccountingMode={
                                            rentRollAccountingMode
                                        }
                                        onSelectCell={handleCellSelect}
                                        matchTypeMap={matchTypeMap}
                                    />
                                ),
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    marginTop: theme.spacing(2),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            downloadResidentTermDirectories(
                                limit,
                                offset,
                                filterState,
                                documentFilter,
                            )
                        }}
                    >
                        <FileCopyIcon />
                    </IconButton>

                    <Pagination
                        page={offset / limit + 1}
                        onChange={(event, value) => {
                            const newOffset = (value - 1) * limit
                            setOffset(newOffset)
                            fetchResidentTermDirectories(
                                limit,
                                newOffset,
                                filterState,
                                documentFilter,
                            )
                        }}
                        count={Math.ceil(totalRows / limit)}
                    />

                    <Container
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            {offset + 1} - {Math.min(offset + limit, totalRows)}{' '}
                            of {totalRows}
                        </span>
                        <Select
                            value={limit}
                            onChange={(event) => {
                                const newLimit = event.target.value as number
                                const newOffset = 0
                                setLimit(newLimit)
                                setOffset(newOffset)
                                fetchResidentTermDirectories(
                                    newLimit,
                                    newOffset,
                                    filterState,
                                    documentFilter,
                                )
                            }}
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            <MenuItem value={10}>10 rows / page</MenuItem>
                            <MenuItem value={25}>25 rows / page</MenuItem>
                            <MenuItem value={50}>50 rows / page</MenuItem>
                        </Select>
                    </Container>
                </Container>
            </Container>

            <FilterPopover
                theme={theme}
                popoverState={filterPopoverState}
                filterState={filterState}
                setFilterState={setFilterState}
                onClear={() => {
                    if (filterPopoverState.context) {
                        const newFilterState = { ...filterState }
                        delete newFilterState[filterPopoverState.context]
                        setFilterState(newFilterState)
                        fetchResidentTermDirectories(
                            limit,
                            offset,
                            newFilterState,
                            documentFilter,
                        )
                        filterPopoverState.handleClose()
                        leaseAuditController.clearEntityValueFilterSuggestions()
                    }
                }}
                onApply={() => {
                    fetchResidentTermDirectories(
                        limit,
                        offset,
                        filterState,
                        documentFilter,
                    )
                }}
            />

            <ResidentDirectoryDetailModal
                theme={theme}
                height={height}
                onClose={handleCloseModal}
                entityValueMap={selectedEntityValueMap}
                classifications={classificationsArray}
                open={!!selectedEntityValueMap}
                selectedClassification={selectedClassification}
                selectedEntityValue={selectedEntityValue}
                setSelectedEntityValue={setSelectedEntityValue}
                leaseAuditController={leaseAuditController}
            />

            <CriteriaGroupModal
                theme={theme}
                openState={criteriaGroupModalOpen}
                onClose={() =>
                    setCriteriaGroupModalOpen({ open: false, groupId: -1 })
                }
                onSave={(name, criteriaState) => {
                    setCriteriaGroupModalOpen({ open: false, groupId: -1 })
                    const criteria: {
                        entity_classification: EntityClassification
                        match_type: MatchType
                    }[] = []
                    Object.keys(criteriaState).forEach((key) => {
                        const entityClassification = key as EntityClassification
                        const matchType = criteriaState[entityClassification]

                        criteria.push({
                            entity_classification: entityClassification,
                            match_type: matchType,
                        })
                    })

                    if (criteriaGroupModalOpen.groupId === -1) {
                        createCriteriaGroup(name, criteria)
                    } else {
                        updateCriteriaGroup(
                            criteriaGroupModalOpen.groupId,
                            name,
                            criteria,
                        )
                    }
                }}
                criteriaGroups={criteriaGroups}
            />
        </Container>
    )
}

const ResidentTermDirectoryRow = (props: {
    dir: ResidentTermDirectoryDetail
    classifications: EntityClassification[]
    theme: Theme
    rentRollAccountingMode: RentRollAccountingMode
    matchTypeMap: MatchTypeMap
    onSelectCell: (
        entityValueMap: DirectoryEntityValueMap,
        selectedClassification: EntityClassification,
        selectedEntityValue: EntityValue | null,
    ) => void
}) => {
    const {
        dir,
        classifications,
        theme,
        rentRollAccountingMode,
        onSelectCell,
        matchTypeMap,
    } = props
    const documents = dir.documents
    const entityValues = documents.flatMap((doc) => doc.entity_values)

    const dirEnttiyValueMap: DirectoryEntityValueMap = new Map()

    entityValues.forEach((ev) => {
        if (!dirEnttiyValueMap.has(ev.classification)) {
            dirEnttiyValueMap.set(ev.classification, {
                leaseInformation: {
                    entityValues: [],
                    modeDetail: null,
                },
                rentRollInformation: {
                    entityValues: [],
                    modeDetail: null,
                },
            })
        }

        if (ev.value_type === 'LEASE') {
            dirEnttiyValueMap
                .get(ev.classification)
                ?.leaseInformation.entityValues.push(ev)
        } else if (
            ev.value_type === rentRollAccountingMode ||
            ev.value_type === 'RENT_ROLL'
        ) {
            dirEnttiyValueMap
                .get(ev.classification)
                ?.rentRollInformation.entityValues.push(ev)
        }
    })

    dirEnttiyValueMap.forEach((values, classification) => {
        const classLeaseValues = values.leaseInformation.entityValues
        const classRentRollValues = values.rentRollInformation.entityValues

        const leaseValueCounts: Map<string, number> = new Map()
        const rentRollValueCounts: Map<string, number> = new Map()

        classLeaseValues.forEach((ev) => {
            if (!ev.value) return
            leaseValueCounts.set(
                ev.value,
                (leaseValueCounts.get(ev.value) || 0) + 1,
            )
        })

        classRentRollValues.forEach((ev) => {
            if (!ev.value) return
            rentRollValueCounts.set(
                ev.value,
                (rentRollValueCounts.get(ev.value) || 0) + 1,
            )
        })

        const leaseMode = getModeDetail(
            leaseValueCounts,
            classLeaseValues.length,
        )
        const rentRollMode = getModeDetail(
            rentRollValueCounts,
            classRentRollValues.length,
        )

        dirEnttiyValueMap.set(classification, {
            leaseInformation: {
                ...values.leaseInformation,
                modeDetail: leaseMode,
            },
            rentRollInformation: {
                ...values.rentRollInformation,
                modeDetail: rentRollMode,
            },
        })
    })

    let requiredMatchCount = 0
    let currentMatchCount = 0

    dirEnttiyValueMap.forEach((values, classification) => {
        const matchType = matchTypeMap.get(classification) || 'NOT_REQUIRED'

        const leaseModeDetail = values.leaseInformation.modeDetail
        const rentRollModeDetail = values.rentRollInformation.modeDetail

        if (matchType === 'NOT_REQUIRED') {
            return
        }

        requiredMatchCount++
        if (!leaseModeDetail || !rentRollModeDetail) {
            return
        }

        if (leaseModeDetail.mode === rentRollModeDetail.mode) {
            currentMatchCount++
        }
    })

    let matchOrnament = <ErrorOutlineIcon color="error" />
    if (requiredMatchCount === currentMatchCount) {
        matchOrnament = <CheckCircleOutlineIcon color="primary" />
    }

    let pairedSuggestionOrnament = null
    if (dir.paired_suggestion) {
        pairedSuggestionOrnament = (
            <Tooltip title="Documents paired without exact match">
                <MergeTypeIcon color="primary" />
            </Tooltip>
        )
    }

    return (
        <TableRow>
            <TableCell>
                {matchOrnament} {pairedSuggestionOrnament}
            </TableCell>
            {classifications.map((classification) => {
                const leaseMode =
                    dirEnttiyValueMap.get(classification)?.leaseInformation
                        .modeDetail || null
                const rentRollMode =
                    dirEnttiyValueMap.get(classification)?.rentRollInformation
                        .modeDetail || null

                return (
                    <EntityValueCell
                        key={classification}
                        classification={classification}
                        leaseValues={
                            dirEnttiyValueMap.get(classification)
                                ?.leaseInformation.entityValues || []
                        }
                        rentRollValues={
                            dirEnttiyValueMap.get(classification)
                                ?.rentRollInformation.entityValues || []
                        }
                        leaseMode={leaseMode}
                        rentRollMode={rentRollMode}
                        onSelectCell={() => {
                            const evMap = dirEnttiyValueMap.get(classification)
                            const leaseValues =
                                evMap?.leaseInformation.entityValues || []
                            const rentRollValues =
                                evMap?.rentRollInformation.entityValues || []
                            const firstValue =
                                leaseValues.length > 0
                                    ? leaseValues[0]
                                    : rentRollValues.length > 0
                                    ? rentRollValues[0]
                                    : null

                            onSelectCell(
                                dirEnttiyValueMap,
                                classification,
                                firstValue,
                            )
                        }}
                        theme={theme}
                        rentRollAccountingMode={rentRollAccountingMode}
                        matchType={
                            matchTypeMap.get(classification) || 'NOT_REQUIRED'
                        }
                    />
                )
            })}
        </TableRow>
    )
}

const EntityValueCell = (props: {
    leaseValues: EntityValue[]
    rentRollValues: EntityValue[]
    leaseMode: ModeDetail | null
    rentRollMode: ModeDetail | null
    theme: Theme
    onSelectCell: () => void
    rentRollAccountingMode: RentRollAccountingMode
    matchType: MatchType
    classification: EntityClassification
}) => {
    const {
        leaseValues,
        rentRollValues,
        leaseMode,
        rentRollMode,
        onSelectCell,
        theme,
        rentRollAccountingMode,
        matchType,
        classification,
    } = props

    let backgroundColor = theme.palette.background.default

    const hasLeaseMode = leaseMode !== null
    const hasRentRollMode = rentRollMode !== null

    let leaseConcensus = true
    let rentRollConcensus = true

    if (hasLeaseMode) {
        leaseConcensus = leaseMode.concensus
    }

    if (hasRentRollMode) {
        rentRollConcensus = rentRollMode.concensus
    }

    if (!leaseConcensus || !rentRollConcensus) {
        backgroundColor = '#91e2ed'
    }

    // if (matchType !== 'NOT_REQUIRED') {
    //     if (!leaseMode || !rentRollMode) {
    //         backgroundColor = '#e6ce61'
    //     }
    // }

    // Does one of the document types not have a value at all?
    const missingLeaseValue = leaseValues.length === 0
    const missingRentRollValue = rentRollValues.length === 0

    const leaseCount = leaseMode?.modeCount ?? 0
    const rentRollCount = rentRollMode?.modeCount ?? 0

    let valueLabel = ''
    if (rentRollCount > leaseCount) {
        valueLabel = rentRollMode?.mode || 'N/A'
    } else {
        valueLabel = leaseMode?.mode || 'N/A'
    }

    const noValues = leaseCount === 0 && rentRollCount === 0

    if (noValues) {
        valueLabel = 'N/A'
    }

    let missingValueOrnament: React.ReactNode | null = null
    if (missingLeaseValue || missingRentRollValue) {
        let missingValue = ''
        if (missingLeaseValue && missingRentRollValue) {
            missingValue = 'Missing Lease Value and Rent Roll Value'
        } else if (missingLeaseValue) {
            missingValue = 'Missing Lease Value'
        } else {
            missingValue = 'Missing Rent Roll Value'
        }
        missingValueOrnament = (
            <Container style={{ flex: 1 }}>
                <div style={{ flex: 1 }} />
                <Tooltip title={missingValue}>
                    <PriorityHighIcon color="error" style={{ fontSize: 12 }} />
                </Tooltip>
            </Container>
        )
    }

    if (leaseMode === null && leaseCount > 0) {
        backgroundColor = '#e6ce61'
    }

    if (rentRollMode === null && rentRollCount > 0) {
        backgroundColor = '#e6ce61'
    }

    if (matchType === 'ANY_REQUIRED') {
        if (leaseMode?.mode !== rentRollMode?.mode) {
            backgroundColor = theme.palette.GO_BACK.main
        }
    } else if (matchType === 'SIGNED_REQUIRED') {
        if (leaseMode?.mode !== rentRollMode?.mode) {
            backgroundColor = theme.palette.GO_BACK.main
        }
    }

    return (
        <TableCell
            style={{
                backgroundColor: backgroundColor,
                color: theme.palette.getContrastText(backgroundColor),
                cursor: 'pointer',
            }}
            className={styles.Cell}
            onClick={onSelectCell}
        >
            <Container style={{ alignItems: 'center' }}>
                <span className={styles.Cell}>{valueLabel}</span>
                {missingValueOrnament}
            </Container>
        </TableCell>
    )
}

const getModeDetail = (
    valueCounts: Map<string, number>,
    numValues: number,
): ModeDetail | null => {
    let mode: string | null = null
    let modeCount = 0
    valueCounts.forEach((count, value) => {
        if (count > modeCount) {
            mode = value
            modeCount = count
        }
    })

    if (mode === null) {
        return null
    }

    return { mode, modeCount, concensus: modeCount === numValues }
}

const FilterHeadCell = (props: {
    filterEnabled: boolean
    theme: Theme
    displayString: string
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onClear: () => void
}) => {
    const { theme, displayString, onClick, filterEnabled } = props

    return (
        <TableCell onClick={onClick} style={{ cursor: 'pointer' }}>
            <Container style={{ flexDirection: 'row', alignItems: 'center' }}>
                <span>{displayString}</span>

                <Tooltip title="Clear">
                    <IconButton
                        disabled={!filterEnabled}
                        onClick={(e) => {
                            e.stopPropagation()
                            props.onClear()
                        }}
                    >
                        <FilterListIcon
                            color={filterEnabled ? 'primary' : 'disabled'}
                        />
                    </IconButton>
                </Tooltip>
            </Container>
        </TableCell>
    )
}

type MatchTypeMap = Map<EntityClassification, MatchType>

type ModeDetail = {
    mode: string
    modeCount: number
    concensus: boolean
}

type DocumentTypeValues = {
    entityValues: EntityValue[]
    modeDetail: ModeDetail | null
}

export type DirectoryEntityValueMap = Map<
    EntityClassification,
    {
        leaseInformation: DocumentTypeValues
        rentRollInformation: DocumentTypeValues
    }
>
