import React from 'react'
import {
    Checkbox,
    Divider,
    IconButton,
    TextField,
    useTheme,
} from '@material-ui/core'
import { Container, InfrastructureFilterChips } from '../../../components'
import { SchedulerHeaderNav } from './SchedulerHeaderNav'
import SearchIcon from '@material-ui/icons/Search'

import RefreshIcon from '@material-ui/icons/Refresh'

import {
    AreaStatusTag,
    BaseWorkorder,
    ListVendor,
    Schedule,
    Service,
    UnitConfig,
    WorkorderAnalyticDict,
} from '../../../models'
import { toMMDDYYYY } from '../../../helpers'
import { Location, LocationSelection } from '../../../store'
import { SchedulerAnalyticsHeadline } from './SchedulerAnalyticsHeadline'
import { ScheduleFilterController } from '../../../hooks/useFilter'

const HEADER_HEIGHT = 128

interface Props {
    schedule?: Schedule
    filterOpen: boolean
    filterController: ScheduleFilterController
    locationSelection: LocationSelection
    vendorList: ListVendor[]
    unitConfigList: UnitConfig[]
    serviceList: Service[]
    analyticDict: WorkorderAnalyticDict
    editMode: boolean
    analyticsOpen: boolean
    workorderList?: BaseWorkorder[]
    areaStatusTagList?: AreaStatusTag[]
    openProjectNotes: boolean

    refreshWorkorders: () => void
    setLocationSelection: (location?: Location) => void
    clickAssign: () => void
    clickBudget: () => void
    clickFilter: () => void
    clickAnalytics: () => void
    setEditMode: (editMode: boolean) => void
    clickKeyTracking: () => void
    clickSmartScheduler: () => void
    clickProjectNotes: () => void
    clickChangeOrder: () => void
    downloadUnitNotesReport: () => void
}

export const SchedulerHeader = (props: Props) => {
    const {
        schedule,
        filterController,
        locationSelection,
        setLocationSelection,
        vendorList,
        unitConfigList,
        serviceList,
        analyticDict,
        editMode,
        refreshWorkorders,
        areaStatusTagList,
    } = props
    const theme = useTheme()

    const startDateStr = schedule?.start_date
    const endDateStr = schedule?.end_date

    const startDate = startDateStr ? new Date(startDateStr) : undefined
    const endDate = endDateStr ? new Date(endDateStr) : undefined

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container
                style={{
                    minHeight: HEADER_HEIGHT,
                    height: 'auto',
                    justifyContent: 'center',
                    padding: theme.spacing(1, 2),
                }}
            >
                <Container style={{ flexDirection: 'column' }}>
                    <Container>
                        <Container
                            style={{
                                flexDirection: 'column',
                                marginRight: theme.spacing(2),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {schedule?.name}
                            </span>

                            <span style={{ ...theme.typography.subtitle2 }}>
                                {schedule?.schedule_id}
                            </span>
                        </Container>

                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                flexDirection: 'column',
                            }}
                        >
                            <span style={{ marginRight: theme.spacing(1) }}>
                                {toMMDDYYYY(startDate)}
                            </span>
                            <span style={{ marginRight: theme.spacing(1) }}>
                                {toMMDDYYYY(endDate)}
                            </span>
                        </Container>

                        <IconButton onClick={refreshWorkorders}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    </Container>

                    <TextField
                        variant="outlined"
                        label="Unit Search"
                        value={filterController.filter.unitSearch}
                        onChange={(e) => {
                            filterController.setInfrastructureFilter(
                                'unitSearch',
                                e.target.value,
                            )
                        }}
                        size="small"
                        InputProps={{
                            endAdornment: <SearchIcon />,
                        }}
                    />
                </Container>

                <SchedulerAnalyticsHeadline
                    analyticDict={analyticDict}
                    scheduleDetail={schedule}
                    areaStatusTagList={areaStatusTagList}
                />

                <SchedulerHeaderNav {...props} />
            </Container>
            <Divider />

            <Container
                style={{
                    flex: 1,
                    paddingTop: theme.spacing(1),
                }}
            >
                <InfrastructureFilterChips
                    filter={filterController.filter}
                    locationSelection={locationSelection}
                    setLocationSelection={setLocationSelection}
                    containerStyle={{ flex: 1 }}
                    hideInspectionChips
                    vendorList={vendorList}
                    unitConfigList={unitConfigList}
                    serviceList={serviceList}
                    disableChips={editMode}
                />
            </Container>
        </Container>
    )
}
