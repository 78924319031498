import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core'

import { _useFinderSelection, useCompany, useUser } from '../../hooks'
import { UpdateWorkOrderRequest, WorkorderResponse } from '../../store'
import { format, isToday } from 'date-fns'
import { VendorCell } from './VendorCell'
import { VendorHeaderCell } from './VendorHeaderCell'

import {
    ListVendor,
    User,
    Service,
    Schedule,
    Inspection,
    InspectionDataResponse,
} from '../../models'
import { resolveRoute, Routes } from '../../helpers'
import { UserHeaderCell } from './UserHeaderCell'
import { getInspectionListFromUnitInspectionRequest } from '../../hooks/useInspectionData'
import { InspectionCell } from './InspectionCell'
import { JbDrawerId, MY_TEAM } from './JobBoardHome'
import { workorderContext } from '../../contexts'

interface Props {
    filteredWorkorderList: WorkorderResponse[]
    workorderList: WorkorderResponse[]
    userList: User[]
    sortedUsers: User[]
    vendorList: ListVendor[]
    serviceList: Service[]
    workorderMap: Map<string, WorkorderResponse[]>
    vendorFilter: number
    sortedVendors: ListVendor[]
    dateRange: Date[]
    scheduleList: Schedule[]
    inspectionData: InspectionDataResponse
    inspectionList: Inspection[]
    analyticData: any
    getInspectionListFromUnitInspection: (
        request: getInspectionListFromUnitInspectionRequest,
    ) => Promise<Inspection[]>
    openDetailDrawer: (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => void
}

const useStyles = makeStyles((theme) => ({
    jobBoardContainer: {
        height: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'max-content',
    },
    headerRow: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
    },
    dateColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    },
    dateHeaderCell: {
        padding: theme.spacing(1, 1, 0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        fontWeight: theme.typography.fontWeightBold,
    },
    dateCell: {
        padding: theme.spacing(0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dateText: {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
    },
    workorderCount: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
    },
    todayCell: {
        backgroundColor: theme.palette.primary.light,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    vendorHeaderCell: {
        padding: theme.spacing(0, 1),
        maxWidth: 300,
        minWidth: 300,
        marginRight: theme.spacing(2),
    },
    workorderCell: {
        padding: theme.spacing(0, 1),
        minWidth: 300,
        maxWidth: 300,
        marginRight: theme.spacing(2),
    },
    row: {
        display: 'flex',
    },
    tabStyle: {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'none',
    },
}))

export const JobBoard = (props: Props) => {
    const {
        filteredWorkorderList,
        workorderList,
        userList,
        sortedUsers,
        vendorList,
        workorderMap,
        vendorFilter,
        sortedVendors,
        serviceList,
        dateRange,
        scheduleList,
        inspectionData,
        inspectionList,
        analyticData,
        getInspectionListFromUnitInspection,
        openDetailDrawer,
    } = props

    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const [
        draggedWorkorder,
        setDraggedWorkorder,
    ] = useState<WorkorderResponse | null>(null)

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollIntervalRef = useRef<number | null>(null)

    const { workspaceUser } = useUser()

    const { updateVendorServices } = useCompany()

    const {
        updateWorkorder,
        updateWorkorderState,
        transitionWorkorder,
    } = workorderContext()

    useEffect(() => {
        // Clean up the interval on component unmount
        return () => {
            if (scrollIntervalRef.current) {
                clearInterval(scrollIntervalRef.current)
            }
        }
    }, [])

    const handleDragStart = (e: React.DragEvent, workorderId: string) => {
        const workorder = filteredWorkorderList.find(
            (wo) => wo.id.toString() === workorderId,
        )
        if (workorder && !workorder.price_locked) {
            e.dataTransfer.setData('text/plain', workorderId)
            setDraggedWorkorder(workorder)
            startAutoScroll(e)
        } else {
            e.preventDefault()
        }
    }

    const startAutoScroll = (
        e: React.DragEvent,
        columnElement?: HTMLElement | null,
    ) => {
        // Clear any existing interval
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
        }

        scrollIntervalRef.current = window.setInterval(() => {
            if (scrollContainerRef.current) {
                const container = scrollContainerRef.current
                const containerRect = container.getBoundingClientRect()
                const horizontalScrollSpeed = 5
                const verticalScrollSpeed = 5

                // Horizontal scrolling
                if (e.clientX > containerRect.right - 50) {
                    container.scrollLeft += horizontalScrollSpeed
                } else if (e.clientX < containerRect.left + 50) {
                    container.scrollLeft -= horizontalScrollSpeed
                }

                // Vertical scrolling
                if (columnElement) {
                    const columnRect = columnElement.getBoundingClientRect()
                    if (e.clientY > columnRect.bottom - 50) {
                        columnElement.scrollTop += verticalScrollSpeed
                    } else if (e.clientY < columnRect.top + 50) {
                        columnElement.scrollTop -= verticalScrollSpeed
                    }
                }
            }
        }, 16) // ~60fps
    }

    const stopAutoScroll = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
            scrollIntervalRef.current = null
        }
    }

    const handleDrop = (
        workorderId: string,
        newId: number,
        newDateKey: string,
        isUser?: boolean,
    ) => {
        const workorder = workorderList?.find(
            (wo) => wo.id.toString() === workorderId,
        )

        let serviceValid = false
        let newVendor: ListVendor | null = null
        let newUser: User | null = null

        if (isUser) {
            newUser = userList.find((u) => u.id === newId) ?? null
            serviceValid = true
        } else {
            newVendor = vendorList.find((v) => v.id === newId) ?? null

            if (newId === -1) {
                serviceValid = true
            } else {
                serviceValid =
                    newVendor?.services.some(
                        (service) => service.id === workorder?.service_id,
                    ) ?? false
            }
        }

        if (workorder && !workorder.price_locked && serviceValid) {
            // Parse the newDateKey
            const [year, month, day] = newDateKey.split('-').map(Number)

            // Create new Date objects from the original start_date and end_date
            const originalStartDate = new Date(workorder.start_date)
            const originalEndDate = new Date(workorder.end_date)

            // Check if original start_date and end_date have the same date
            const sameOriginalDate =
                originalStartDate.getFullYear() ===
                    originalEndDate.getFullYear() &&
                originalStartDate.getMonth() === originalEndDate.getMonth() &&
                originalStartDate.getDate() === originalEndDate.getDate()

            // Create a new Date object for start_date with the new date and original time
            const newStartDate = new Date(
                year,
                month - 1, // JavaScript months are 0-indexed
                day,
                originalStartDate.getHours(),
                originalStartDate.getMinutes(),
                originalStartDate.getSeconds(),
                originalStartDate.getMilliseconds(),
            )

            // Calculate the difference in days between the new and original start dates
            const daysDifference = Math.round(
                (newStartDate.getTime() - originalStartDate.getTime()) /
                    (1000 * 60 * 60 * 24),
            )

            let newEndDate
            if (sameOriginalDate) {
                // If original dates were the same, set new end_date to new date with original end time
                newEndDate = new Date(
                    year,
                    month - 1,
                    day,
                    originalEndDate.getHours(),
                    originalEndDate.getMinutes(),
                    originalEndDate.getSeconds(),
                    originalEndDate.getMilliseconds(),
                )
            } else {
                // If original dates were different, adjust the end date by the same number of days
                newEndDate = new Date(originalEndDate)
                newEndDate.setDate(newEndDate.getDate() + daysDifference)
            }

            const updatedWorkorder = {
                ...workorder,
                start_date: newStartDate.toISOString(),
                end_date: newEndDate.toISOString(),
            }

            const request: UpdateWorkOrderRequest = {
                workorderId: workorder.id,
                body: {
                    start_date: newStartDate.toISOString(),
                    end_date: newEndDate.toISOString(),
                },
            }

            if (newVendor) {
                updatedWorkorder.vendor_id = newVendor.id
                updatedWorkorder.user_id = null
                request.body.assign_to_id = newVendor.id
                request.body.assign_to_type = 'vendor'
            } else if (newUser) {
                updatedWorkorder.user_id = newUser.id
                updatedWorkorder.vendor_id = null
                request.body.assign_to_id = newUser.id
                request.body.assign_to_type = 'user'
            }

            // Update the workorder in your our local state
            updateWorkorderState(updatedWorkorder)

            // Update workorder on the backend
            updateWorkorder(request).catch(() =>
                updateWorkorderState(workorder),
            )
        }

        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    const handleDragOver = (
        e: React.DragEvent,
        columnElement: HTMLElement | null,
    ) => {
        e.preventDefault()
        startAutoScroll(e, columnElement)
    }

    const handleDragEnd = () => {
        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    const navigateToScheduleDetail = (scheduleId: number) => {
        const project = scheduleList.find(
            (project) => project.id === scheduleId,
        )

        let route = Routes.apartmentScheduleDetail
        if (project && !project.active) {
            route = Routes.apartmentScheduleArchivedDetail
        }

        history.push(resolveRoute(route, ':id', scheduleId))
    }

    const navigateToInspectionDetail = (inspectionId: number) => {
        const route = Routes.inspectionDetail

        const url = resolveRoute(route, ':id', inspectionId)

        window.open(url, '_blank')
    }

    return (
        <div
            className={classes.jobBoardContainer}
            ref={scrollContainerRef}
            onDragEnd={handleDragEnd}
        >
            <div className={classes.tableContainer}>
                <div className={classes.headerRow}>
                    <div
                        className={`${classes.dateColumn} ${classes.dateHeaderCell}`}
                    >
                        Date
                    </div>
                    {vendorFilter !== MY_TEAM
                        ? sortedVendors.map((vendor) => (
                              <div
                                  key={`VENDOR_${vendor.id}`}
                                  className={classes.vendorHeaderCell}
                              >
                                  <VendorHeaderCell
                                      vendor={vendor}
                                      theme={theme}
                                      draggedWorkorder={draggedWorkorder}
                                      serviceList={serviceList}
                                      updateVendorServices={
                                          updateVendorServices
                                      }
                                  />
                              </div>
                          ))
                        : sortedUsers.map((user) => (
                              <div
                                  key={`USER_${user.id}`}
                                  className={classes.vendorHeaderCell}
                              >
                                  <UserHeaderCell user={user} theme={theme} />
                              </div>
                          ))}
                </div>
                {dateRange.map((date) => {
                    const dateKey = format(date, 'yyyy-MM-dd')
                    const isDateToday = isToday(date)
                    const workorderCount =
                        workorderMap.get(dateKey)?.length ?? 0

                    return (
                        <div key={dateKey} className={classes.row}>
                            <div
                                className={`${classes.dateColumn} ${
                                    classes.dateCell
                                } ${isDateToday ? classes.todayCell : ''}`}
                            >
                                <span className={classes.dateText}>
                                    {format(date, 'EEE, MMM d')}
                                </span>
                                <span className={classes.workorderCount}>
                                    {workorderCount} workorder
                                    {workorderCount !== 1 ? 's' : ''}
                                </span>
                            </div>
                            {vendorFilter !== MY_TEAM
                                ? sortedVendors.map((vendor) => (
                                      <div
                                          key={`${dateKey}-${vendor.id}-VENDOR`}
                                          className={classes.workorderCell}
                                      >
                                          <VendorCell
                                              vendor={vendor}
                                              dateKey={dateKey}
                                              workorders={
                                                  workorderMap
                                                      .get(dateKey)
                                                      ?.filter(
                                                          (wo) =>
                                                              vendor.id ===
                                                              wo.vendor_id,
                                                      ) ?? []
                                              }
                                              theme={theme}
                                              workspaceUser={workspaceUser}
                                              transitionWorkorder={
                                                  transitionWorkorder
                                              }
                                              openDetailDrawer={
                                                  openDetailDrawer
                                              }
                                              onDragStart={handleDragStart}
                                              onDrop={handleDrop}
                                              onDragOver={handleDragOver}
                                              draggedWorkorder={
                                                  draggedWorkorder
                                              }
                                              isLastColumn={
                                                  vendor.id ===
                                                  sortedVendors[
                                                      sortedVendors.length - 1
                                                  ].id
                                              }
                                              navigateToScheduleDetail={
                                                  navigateToScheduleDetail
                                              }
                                          />
                                      </div>
                                  ))
                                : sortedUsers.map((user) => (
                                      <div
                                          key={`${dateKey}-${user.id}-USER`}
                                          className={classes.workorderCell}
                                      >
                                          <InspectionCell
                                              inspectionData={inspectionData}
                                              date={dateKey}
                                              user={user}
                                              theme={theme}
                                              inspectionList={inspectionList}
                                              analyticData={analyticData}
                                              getInspectionListFromUnitInspection={
                                                  getInspectionListFromUnitInspection
                                              }
                                              navigateToInspectionDetail={
                                                  navigateToInspectionDetail
                                              }
                                          />
                                          <VendorCell
                                              user={user}
                                              dateKey={dateKey}
                                              workorders={
                                                  workorderMap
                                                      .get(dateKey)
                                                      ?.filter(
                                                          (wo) =>
                                                              user.id ===
                                                              wo.user_id,
                                                      ) ?? []
                                              }
                                              theme={theme}
                                              workspaceUser={workspaceUser}
                                              transitionWorkorder={
                                                  transitionWorkorder
                                              }
                                              openDetailDrawer={
                                                  openDetailDrawer
                                              }
                                              onDragStart={handleDragStart}
                                              onDrop={handleDrop}
                                              onDragOver={handleDragOver}
                                              draggedWorkorder={
                                                  draggedWorkorder
                                              }
                                              isLastColumn={
                                                  user.id ===
                                                  sortedUsers[
                                                      sortedUsers.length - 1
                                                  ].id
                                              }
                                              navigateToScheduleDetail={
                                                  navigateToScheduleDetail
                                              }
                                          />
                                      </div>
                                  ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
