import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'

import {
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    Tab,
    Tabs,
    TextField,
    Theme,
    createStyles,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'

import { Container, Selector } from '../../../../components'
import { axiosInstance } from '../../../../helpers'
import { setRootUser, setWorkSpaceUser } from '../../../../store'
import LoopIcon from '@material-ui/icons/Loop'
import { InHouseVendorHours } from './InHouseVendorHours'
import { CompanyHourDisplay } from '../../../../containers/VendorSettings/CompanyHourDisplay'
import { useAppDispatch, useCompany, useUser } from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileUploadBtn: {
            position: 'absolute',
            right: '-5%',
            bottom: '-10%',
            color: 'rgb(255, 255, 255)',
            background: 'rgb(44, 128, 255)',
            padding: '4px',
            border: '5px solid #fafafa',
            fontSize: '0.5rem',
            '& .MuiSvgIcon-root': {
                height: '1.2em',
                width: '1.2em',
                '& path': {
                    stroke: '#000',
                },
                '& circle': {
                    stroke: '#FFF',
                    strokeWidth: '10px',
                },
            },
        },
        uploadInput: {
            display: 'none',
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
        indicator: {
            backgroundColor: theme.palette.primary.dark,
        },
        header: {
            ...theme.typography.h4,
            fontWeight: theme.typography.fontWeightBold,
            fontStyle: 'normal',
            lineHeight: '37px',
            marginBottom: '15px',
            marginTop: '5px',
            color: '#2F2E41',
        },
    }),
)

export const PropertyDetails = () => {
    const classes = useStyles()
    const { rootUser, workspaceUser, userList, getUserList } = useUser()
    const theme = useTheme()

    const dispatch = useAppDispatch()

    const [url, setURL] = useState(workspaceUser?.active_workspace.url)
    const [profilePic, setProfilePic] = useState<File | null>(null)
    const [profilePicString, setProfilePicString] = useState(
        workspaceUser?.active_workspace.profile_picture,
    )
    const [loading, setLoading] = useState(false)

    const [allowServiceRequest, setAllowServiceRequest] = useState(
        workspaceUser?.active_workspace.allow_service_request ? true : false,
    )

    const [selectedTab, setSelectedTab] = useState(DETAILS_TAB)

    const { vendorList, setVendorList } = useCompany({
        getVendorList: true,
        cleanUp: true,
    })

    const [backupUserOne, setBackupUserOne] = useState(
        workspaceUser?.active_workspace.on_call_backups?.backup_user_one?.id ??
            -1,
    )
    const [backupUserTwo, setBackupUserTwo] = useState(
        workspaceUser?.active_workspace.on_call_backups?.backup_user_two?.id ??
            -1,
    )
    const [backupUserThree, setBackupUserThree] = useState(
        workspaceUser?.active_workspace.on_call_backups?.backup_user_three
            ?.id ?? -1,
    )

    const [doValidate, setDoValidate] = useState(false)

    useEffect(() => {
        getUserList({ params: { my_team: true } })
    }, [])

    let filePreview =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ5srpTZoAXWINllDurGscx_Pqg_foehqAkQ&usqp=CAU'

    if (profilePic) {
        filePreview = URL.createObjectURL(profilePic)
    } else if (profilePicString) {
        filePreview = profilePicString
    }

    const onSave = () => {
        const body = new FormData()

        if (url) {
            body.append('url', url)
        }

        if (profilePic) {
            body.append('profile_picture', profilePic)
        }

        if (backupUserOne !== -1) {
            const onCallBackReq = new FormData()
            onCallBackReq.append('backup_user_one', backupUserOne.toString())

            if (backupUserTwo !== -1) {
                onCallBackReq.append(
                    'backup_user_two',
                    backupUserTwo.toString(),
                )
            }

            if (backupUserThree !== -1) {
                onCallBackReq.append(
                    'backup_user_three',
                    backupUserThree.toString(),
                )
            }

            const onCallBackReqString = JSON.stringify(
                Object.fromEntries(onCallBackReq.entries()),
            )
            body.append('on_call_backups', onCallBackReqString)
        }

        body.append(
            'allow_service_request',
            allowServiceRequest ? 'True' : 'False',
        )

        setLoading(true)
        axiosInstance
            .patch(
                `company/apartment/${workspaceUser?.active_workspace.id}/`,
                body,
            )
            .then((res) => {
                const updatedUser = workspaceUser
                if (updatedUser?.active_workspace) {
                    updatedUser.active_workspace = res.data
                    dispatch(setWorkSpaceUser(updatedUser))
                }
                toast.success(`${workspaceUser?.active_workspace.name} updated`)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const checkValid = () => {
        if (
            backupUserOne === -1 &&
            (backupUserTwo !== -1 || backupUserThree !== -1)
        ) {
            setDoValidate(true)
            return false
        } else if (backupUserTwo === -1 && backupUserThree !== -1) {
            setDoValidate(true)
            return false
        }
        return true
    }

    const tabStyle: React.CSSProperties = {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    }

    return (
        <>
            <Container>
                <Tabs
                    style={{
                        color: 'black',
                        borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                        flex: 1,
                        textTransform: 'none',
                    }}
                    value={selectedTab}
                    onChange={(_, v) => {
                        if (v !== selectedTab) {
                            setSelectedTab(v)
                        }
                    }}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    <Tab label="Details" style={{ ...tabStyle }} />
                    <Tab label="Property Hours" style={{ ...tabStyle }} />
                    <Tab label="Vendor Hours" style={{ ...tabStyle }} />
                </Tabs>
            </Container>
            {selectedTab === DETAILS_TAB && (
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    <Container className={classes.header}>
                        Property Details
                    </Container>
                    <Container direction="column">
                        <Container style={{ alignItems: 'center' }}>
                            <div
                                style={{
                                    width:
                                        profilePic || profilePicString
                                            ? PROFILE_PIC_WIDTH_LG
                                            : PROFILE_PIC_WIDTH_SM,
                                    height:
                                        profilePic || profilePicString
                                            ? PROFILE_PIC_HEIGHT_LG
                                            : PROFILE_PIC_HEIGHT_SM,
                                    display: 'inline-flex',
                                    position: 'relative',
                                }}
                            >
                                <img src={filePreview} alt="" width={'100%'} />
                                <input
                                    accept="image/png, image/jpeg, image/jpg"
                                    className={classes.uploadInput}
                                    id="icon-button-file"
                                    type="file"
                                    onChange={(event) => {
                                        if (event.target.files) {
                                            setProfilePic(event.target.files[0])
                                        }
                                    }}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        className={classes.fileUploadBtn}
                                        style={{
                                            color: '#FFF',
                                            background: '#2C80FF',
                                            padding: '4px',
                                        }}
                                    >
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            </div>

                            <Container
                                style={{
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    marginLeft: theme.spacing(3),
                                }}
                            >
                                {workspaceUser?.active_workspace.name}
                            </Container>
                        </Container>
                        <Container
                            style={{
                                fontSize: '12px',
                                color: theme.palette.grey[500],
                                marginTop: theme.spacing(1),
                            }}
                        >
                            Recommend Image Size: 1920 x 1080 px
                        </Container>
                        <Container
                            style={{ marginTop: theme.spacing(2) }}
                            direction="column"
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                Website URL:
                            </span>
                            <TextField
                                variant="outlined"
                                value={url}
                                onChange={(e) => setURL(e.target.value)}
                                style={{
                                    width: '400px',
                                }}
                                size="small"
                            />
                        </Container>
                        <Container
                            style={{
                                marginTop: theme.spacing(2),
                                flexDirection: 'column',
                            }}
                        >
                            <Selector
                                label="On Call Backup One"
                                data={userList.filter(
                                    (user) =>
                                        user.id !== backupUserTwo &&
                                        user.id !== backupUserThree,
                                )}
                                searchable
                                currentValue={backupUserOne}
                                onChange={(e) => {
                                    setBackupUserOne(e.target.value as number)
                                }}
                                getDisplayString={(user) => user.name}
                                customStyle={{
                                    formControl: {
                                        width: '400px',
                                    },
                                }}
                                error={
                                    doValidate &&
                                    backupUserOne === -1 &&
                                    (backupUserTwo !== -1 ||
                                        backupUserThree !== -1)
                                }
                                variant="standard"
                            />
                            <Selector
                                label="On Call Backup Two"
                                data={userList.filter(
                                    (user) =>
                                        user.id !== backupUserOne &&
                                        user.id !== backupUserThree,
                                )}
                                searchable
                                currentValue={backupUserTwo}
                                onChange={(e) => {
                                    setBackupUserTwo(e.target.value as number)
                                }}
                                getDisplayString={(user) => user.name}
                                customStyle={{
                                    formControl: {
                                        width: '400px',
                                        marginTop: theme.spacing(1),
                                    },
                                }}
                                error={
                                    doValidate &&
                                    backupUserTwo === -1 &&
                                    backupUserThree !== -1
                                }
                                variant="standard"
                            />
                            <Selector
                                label="On Call Backup Three"
                                data={userList.filter(
                                    (user) =>
                                        user.id !== backupUserOne &&
                                        user.id !== backupUserTwo,
                                )}
                                searchable
                                currentValue={backupUserThree}
                                onChange={(e) => {
                                    setBackupUserThree(e.target.value as number)
                                }}
                                getDisplayString={(user) => user.name}
                                customStyle={{
                                    formControl: {
                                        width: '400px',
                                        marginTop: theme.spacing(1),
                                    },
                                }}
                                variant="standard"
                            />
                        </Container>
                        <Container style={{ marginTop: theme.spacing(2) }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={allowServiceRequest}
                                        color="primary"
                                        onChange={(e) => {
                                            setAllowServiceRequest(
                                                !allowServiceRequest,
                                            )
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label="Allow Service Request"
                                style={{ marginTop: theme.spacing(2) }}
                            />
                        </Container>
                        <Container>
                            <Button
                                variant="contained"
                                style={{
                                    marginTop: theme.spacing(4),
                                    backgroundColor: '#008C85',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (checkValid()) {
                                        onSave()
                                    }
                                }}
                            >
                                {loading ? (
                                    <LoopIcon className={classes.rotateIcon} />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Container>
                    </Container>
                </Container>
            )}

            {selectedTab === PROPERTY_HOURS_TAB &&
                workspaceUser?.active_workspace &&
                rootUser && (
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                        }}
                    >
                        <Container className={classes.header}>
                            Property Hours
                        </Container>
                        <CompanyHourDisplay
                            company={workspaceUser.active_workspace}
                            onAfterUpdate={(companyHours) => {
                                const updatedWorkspace = {
                                    ...workspaceUser?.active_workspace,
                                    company_hours: companyHours,
                                }
                                const updatedRootUser = {
                                    ...rootUser,
                                    workspaces: rootUser.workspaces.map(
                                        (workspace) => {
                                            if (
                                                workspace.active_workspace
                                                    .id ===
                                                workspaceUser?.active_workspace
                                                    .id
                                            ) {
                                                return {
                                                    ...workspace,
                                                    active_workspace: updatedWorkspace,
                                                }
                                            }
                                            return workspace
                                        },
                                    ),
                                }
                                dispatch(
                                    setWorkSpaceUser({
                                        ...workspaceUser,
                                        active_workspace: updatedWorkspace,
                                    }),
                                )
                                dispatch(setRootUser(updatedRootUser))
                            }}
                        />
                    </Container>
                )}

            {selectedTab === VENDOR_HOURS_TAB && (
                <InHouseVendorHours
                    vendorList={vendorList}
                    setVendorList={setVendorList}
                />
            )}
        </>
    )
}

const DETAILS_TAB = 0
const PROPERTY_HOURS_TAB = 1
const VENDOR_HOURS_TAB = 2

const PROFILE_PIC_WIDTH_LG = 300
const PROFILE_PIC_HEIGHT_LG = 200
const PROFILE_PIC_WIDTH_SM = 100
const PROFILE_PIC_HEIGHT_SM = 100
