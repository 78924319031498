import React, { useEffect, useMemo, useState } from 'react'
import { Container, UploadProgress } from '../../components'
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Theme,
    TableContainer,
    IconButton,
    MenuItem,
    Select,
    Popover,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    CircularProgress,
    Checkbox,
    Button,
} from '@material-ui/core'

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { LeaseAuditController } from '../../hooks/documentAudit'
import { DropFiles } from '../../components/DropFiles'
import DetailModal from './DetailModal'
import { usePagination } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import { SearchField } from '../../components/SearchField'
import { toMMDDYYYY } from '../../helpers'
import { PopoverState, usePopover } from '../../hooks/usePopover'
import {
    DocumentOccurance,
    getJobStage,
    getJobStatus,
    PipelineDocumentTicket,
    PENDING,
    IN_PROGRESS,
    COMPLETED,
    FAILED,
    GENERATE_MARKDOWN,
    CLASSIFY_PAGES,
    EXTRACT_DATA,
    GROUP_BY_TERM,
    INITIAL,
    CREATE_PAGES,
} from '../../models'
import { DocumentDetailPopover } from './DocumentDetailPopover'
import { useSelectionMap } from '../../hooks/useSelectionMap'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

export const LeasePdfList = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props

    const [
        selectedDocOccuranceId,
        setSelectedDocOccuranceId,
    ] = useState<number>(-1)

    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)
    const [pipelineFilter, setPipelineFilter] = useState(-1)
    const [stageFilter, setStageFilter] = useState(-1)
    const [stageStatusFilter, setStageStatusFilter] = useState(-1)
    const selectionController = useSelectionMap()
    const [allHiddenSelected, setAllHiddenSelected] = useState(false)

    const popoverState = usePopover<DocumentOccurance | null>()

    const leaseAudit = leaseAuditController.leaseAudit

    const documentOccurances =
        leaseAuditController.leaseDocumentOccurances?.results || []

    useEffect(() => {
        if (isActive) {
            loadDocumentOccurances(
                offset,
                limit,
                pipelineFilter,
                stageFilter,
                stageStatusFilter,
            )
        }
    }, [isActive])

    if (leaseAudit === undefined || leaseAudit === null) {
        return null
    }
    const totalRows = leaseAuditController.leaseDocumentOccurances?.count || 0

    const allSelected =
        selectionController.selectionMap.length === documentOccurances.length
    const nonSelected = selectionController.selectionMap.length === 0

    const intermediate = !allSelected && !nonSelected

    const loadDocumentOccurances = (
        offset: number,
        limit: number,
        pipelineFilter: number,
        stageFilter: number,
        stageStatusFilter: number,
    ) => {
        leaseAuditController.fetchLeaseDocumentOccurances(
            leaseAudit.id,
            offset,
            limit,
            {
                pipeline_status:
                    pipelineFilter === -1 ? undefined : pipelineFilter,
                stage_status:
                    stageStatusFilter === -1 ? undefined : stageStatusFilter,
                stage: stageFilter === -1 ? undefined : stageFilter,
            },
        )
    }

    return (
        <Container
            style={{
                height: height,
                flex: 1,
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            {/* Header */}
            <Container>
                <FormControl style={{ marginLeft: theme.spacing(4) }}>
                    <FormLabel id="lease-document-pipeline-filter-label">
                        Pipeline Filter
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="lease-document-pipeline-filter-label"
                        name="lease-document-pipeline-filter"
                        value={pipelineFilter}
                        onChange={(e) => {
                            const pipelineStatus = Number(e.target.value)
                            setPipelineFilter(pipelineStatus)
                            loadDocumentOccurances(
                                offset,
                                limit,
                                pipelineStatus,
                                stageFilter,
                                stageStatusFilter,
                            )
                        }}
                    >
                        <FormControlLabel
                            value={-1}
                            control={<Radio />}
                            label="All"
                        />
                        <FormControlLabel
                            value={PENDING}
                            control={<Radio />}
                            label="Pending"
                        />
                        <FormControlLabel
                            value={IN_PROGRESS}
                            control={<Radio />}
                            label="In Progress"
                        />
                        <FormControlLabel
                            value={COMPLETED}
                            control={<Radio />}
                            label="Completed"
                        />
                        <FormControlLabel
                            value={FAILED}
                            control={<Radio />}
                            label="Failed"
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl style={{ marginLeft: theme.spacing(4) }}>
                    <FormLabel id="lease-document-stage-filter-label">
                        Stage Filter
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="lease-document-stage-filter-label"
                        name="lease-document-stage-filter"
                        value={stageFilter}
                        onChange={(e) => {
                            const selectedStage = Number(e.target.value)
                            setStageFilter(selectedStage)
                            loadDocumentOccurances(
                                offset,
                                limit,
                                pipelineFilter,
                                selectedStage,
                                stageStatusFilter,
                            )
                        }}
                    >
                        <FormControlLabel
                            value={-1}
                            control={<Radio />}
                            label="All"
                        />
                        <FormControlLabel
                            value={INITIAL}
                            control={<Radio />}
                            label="Initial"
                        />
                        <FormControlLabel
                            value={CREATE_PAGES}
                            control={<Radio />}
                            label="Create Pages"
                        />
                        <FormControlLabel
                            value={GENERATE_MARKDOWN}
                            control={<Radio />}
                            label="Generate Markdown"
                        />
                        <FormControlLabel
                            value={CLASSIFY_PAGES}
                            control={<Radio />}
                            label="Classify Pages"
                        />
                        <FormControlLabel
                            value={EXTRACT_DATA}
                            control={<Radio />}
                            label="Extract Data"
                        />
                        <FormControlLabel
                            value={GROUP_BY_TERM}
                            control={<Radio />}
                            label="Group By Term"
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl style={{ marginLeft: theme.spacing(4) }}>
                    <FormLabel id="lease-document-stage-status-label">
                        Stage Status
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="lease-document-stage-status-label"
                        name="lease-document-stage-status"
                        value={stageStatusFilter}
                        onChange={(e) => {
                            const selectedStageStatus = Number(e.target.value)
                            setStageStatusFilter(selectedStageStatus)
                            loadDocumentOccurances(
                                offset,
                                limit,
                                pipelineFilter,
                                stageFilter,
                                selectedStageStatus,
                            )
                        }}
                    >
                        <FormControlLabel
                            value={-1}
                            control={<Radio />}
                            label="All"
                        />
                        <FormControlLabel
                            value={PENDING}
                            control={<Radio />}
                            label="Pending"
                        />
                        <FormControlLabel
                            value={IN_PROGRESS}
                            control={<Radio />}
                            label="In Progress"
                        />
                        <FormControlLabel
                            value={COMPLETED}
                            control={<Radio />}
                            label="Completed"
                        />
                        <FormControlLabel
                            value={FAILED}
                            control={<Radio />}
                            label="Failed"
                        />
                    </RadioGroup>
                </FormControl>
            </Container>

            <TableContainer style={{ flex: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={allSelected}
                                    indeterminate={intermediate}
                                    onChange={() => {
                                        setAllHiddenSelected(false)
                                        if (nonSelected) {
                                            selectionController.selectAll(
                                                documentOccurances,
                                            )
                                        } else {
                                            selectionController.clearSelections()
                                        }
                                    }}
                                />
                                {allSelected && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={allHiddenSelected}
                                                onChange={(_, checked) => {
                                                    setAllHiddenSelected(
                                                        checked,
                                                    )
                                                }}
                                            />
                                        }
                                        label={`Select all ${totalRows} documents`}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                <span>Created Date</span>
                            </TableCell>
                            <TableCell>
                                <span>Batch</span>
                            </TableCell>
                            <TableCell>
                                <span>Status</span>
                            </TableCell>
                            <TableCell>
                                <span># Stages</span>
                            </TableCell>
                            <TableCell>
                                <span>Resident</span>
                            </TableCell>
                            <TableCell>
                                <span>File Name</span>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leaseAuditController.loading
                            .fetchDocumentOccurances ? (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            documentOccurances?.map((docOccurance) => {
                                const document = docOccurance.document
                                const createdDate = new Date(
                                    document.created_date,
                                )
                                const resident =
                                    document.entity_values.find(
                                        (ev) =>
                                            ev.classification === 'resident',
                                    )?.value || 'N/A'

                                let statusLabel = 'Unassigned'
                                if (document.active_ticket !== null) {
                                    statusLabel = getJobStatus(
                                        document.active_ticket.status,
                                    )
                                }
                                return (
                                    <TableRow key={docOccurance.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectionController.isIdSelected(
                                                    docOccurance.id,
                                                )}
                                                onChange={(e) => {
                                                    selectionController.setIdSelectionValue(
                                                        docOccurance.id,
                                                        e.target.checked,
                                                    )
                                                    setAllHiddenSelected(false)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {toMMDDYYYY(createdDate)}
                                        </TableCell>
                                        <TableCell>
                                            {docOccurance.document_batch}
                                        </TableCell>
                                        <TableCell
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation()

                                                popoverState.handleOpen(
                                                    e,
                                                    docOccurance,
                                                )
                                            }}
                                        >
                                            <span>{statusLabel}</span>
                                        </TableCell>

                                        <TableCell>
                                            <span>
                                                {document.active_ticket?.jobs
                                                    .length ?? 0}
                                            </span>
                                        </TableCell>

                                        <TableCell>
                                            <span>{resident}</span>
                                        </TableCell>

                                        <TableCell>
                                            {document.file_name}
                                        </TableCell>

                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedDocOccuranceId(
                                                        docOccurance.id,
                                                    )
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    marginTop: theme.spacing(2),
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        if (allHiddenSelected) {
                            leaseAuditController.runTickets(
                                leaseAudit.id,
                                'PDF',
                                undefined,
                                pipelineFilter === -1
                                    ? undefined
                                    : pipelineFilter,
                            )
                            return
                        }
                        const selectedIds: number[] = []
                        Object.keys(selectionController.selectionMap).forEach(
                            (id) => {
                                const numId = Number(id)
                                if (isNaN(numId)) {
                                    return
                                }

                                if (selectionController.selectionMap[numId]) {
                                    selectedIds.push(numId)
                                }
                            },
                        )

                        leaseAuditController.runTickets(
                            leaseAudit.id,
                            'PDF',
                            selectedIds,
                            pipelineFilter === -1 ? undefined : pipelineFilter,
                        )
                    }}
                >
                    Run pipeline
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        marginLeft: theme.spacing(2),
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        if (allHiddenSelected) {
                            leaseAuditController.addStagesToTickets(
                                leaseAudit.id,
                                [
                                    GENERATE_MARKDOWN,
                                    CLASSIFY_PAGES,
                                    EXTRACT_DATA,
                                    GROUP_BY_TERM,
                                ],
                                limit,
                                offset,
                                undefined,
                                pipelineFilter === -1
                                    ? undefined
                                    : pipelineFilter,
                            )
                            return
                        }
                        const selectedIds: number[] = []
                        Object.keys(selectionController.selectionMap).forEach(
                            (id) => {
                                const numId = Number(id)
                                if (isNaN(numId)) {
                                    return
                                }

                                if (selectionController.selectionMap[numId]) {
                                    selectedIds.push(numId)
                                }
                            },
                        )

                        leaseAuditController.addStagesToTickets(
                            leaseAudit.id,
                            [
                                GENERATE_MARKDOWN,
                                CLASSIFY_PAGES,
                                EXTRACT_DATA,
                                GROUP_BY_TERM,
                            ],
                            limit,
                            offset,
                            selectedIds,
                            pipelineFilter === -1 ? undefined : pipelineFilter,
                        )
                    }}
                >
                    Add paid stages
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        marginLeft: theme.spacing(2),
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        if (allHiddenSelected) {
                            leaseAuditController.assignNewTickets(
                                leaseAudit.id,
                                undefined,
                                pipelineFilter === -1
                                    ? undefined
                                    : pipelineFilter,
                                stageFilter === -1 ? undefined : stageFilter,
                            )
                            return
                        }
                        const selectedIds: number[] = []
                        Object.keys(selectionController.selectionMap).forEach(
                            (id) => {
                                const numId = Number(id)
                                if (isNaN(numId)) {
                                    return
                                }

                                if (selectionController.selectionMap[numId]) {
                                    selectedIds.push(numId)
                                }
                            },
                        )

                        leaseAuditController.assignNewTickets(
                            leaseAudit.id,
                            selectedIds,
                            pipelineFilter === -1 ? undefined : pipelineFilter,
                            stageFilter === -1 ? undefined : stageFilter,
                        )
                    }}
                >
                    Assign new tickets
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        marginLeft: theme.spacing(2),
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        if (allHiddenSelected) {
                            leaseAuditController.resetFailedTickets(
                                leaseAudit.id,
                                'PDF',
                                undefined,
                                pipelineFilter === -1
                                    ? undefined
                                    : pipelineFilter,
                                stageFilter === -1 ? undefined : stageFilter,
                            )
                            return
                        }
                        const selectedIds: number[] = []
                        Object.keys(selectionController.selectionMap).forEach(
                            (id) => {
                                const numId = Number(id)
                                if (isNaN(numId)) {
                                    return
                                }

                                if (selectionController.selectionMap[numId]) {
                                    selectedIds.push(numId)
                                }
                            },
                        )

                        leaseAuditController.resetFailedTickets(
                            leaseAudit.id,
                            'PDF',
                            selectedIds,
                            pipelineFilter === -1 ? undefined : pipelineFilter,
                            stageFilter === -1 ? undefined : stageFilter,
                        )
                    }}
                >
                    Reset failed tickets
                </Button>

                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        page={offset / limit + 1}
                        onChange={(event, value) => {
                            const newOffset = (value - 1) * limit
                            setOffset(newOffset)
                            loadDocumentOccurances(
                                newOffset,
                                limit,
                                pipelineFilter,
                                stageFilter,
                                stageStatusFilter,
                            )
                        }}
                        count={Math.ceil(totalRows / limit)}
                    />

                    <Container
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            {offset + 1} - {Math.min(offset + limit, totalRows)}{' '}
                            of {totalRows}
                        </span>
                        <Select
                            value={limit}
                            onChange={(event) => {
                                const newLimit = event.target.value as number
                                const newOffset = 0
                                setLimit(newLimit)
                                setOffset(newOffset)
                                loadDocumentOccurances(
                                    newOffset,
                                    newLimit,
                                    pipelineFilter,
                                    stageFilter,
                                    stageStatusFilter,
                                )
                            }}
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            <MenuItem value={10}>10 rows / page</MenuItem>
                            <MenuItem value={25}>25 rows / page</MenuItem>
                            <MenuItem value={50}>50 rows / page</MenuItem>
                        </Select>
                    </Container>
                </Container>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <DropFiles
                        onDrop={(files) => {
                            if (leaseAuditController.leaseAudit) {
                                leaseAuditController.uploadLeaseContractBatch(
                                    files,
                                    leaseAuditController.leaseAudit.id,
                                )
                            }
                        }}
                        displayString="Drop leases here to upload"
                    />
                </Container>
            </Container>

            <UploadProgress
                theme={theme}
                uploadBatch={leaseAuditController.uploadBatch}
                currentFileIndex={leaseAuditController.currentFileIndex}
            />

            <DetailModal
                selectedDocOccuranceId={selectedDocOccuranceId}
                onClose={() => setSelectedDocOccuranceId(-1)}
                theme={theme}
                leaseAuditController={leaseAuditController}
            />

            <DocumentDetailPopover
                popoverState={popoverState}
                theme={theme}
                leaseAuditController={leaseAuditController}
            />
        </Container>
    )
}
