import React from 'react'
import { JobBoardHome } from './JobBoardHome'
import {
    RFPContext,
    useRFPState,
    useWorkorderState,
    WorkorderContext,
} from '../../contexts'

export const JobBoardRoot = () => {
    const workorderState = useWorkorderState()
    const rfpState = useRFPState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <RFPContext.Provider value={rfpState}>
                <JobBoardHome />
            </RFPContext.Provider>
        </WorkorderContext.Provider>
    )
}
