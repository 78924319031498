import React, { useState } from 'react'

import moment from 'moment'

import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    makeStyles,
    Popover,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { Inspection, InspectionDataResponse, User } from '../../models'
import { Container } from '../../components'
import { ReactComponent as ClipboardTickSBIcon } from '../../assets/icons/clipboard-tick.svg'
import {
    getInspectionListFromUnitInspectionRequest,
    InspectionAnalyticRes,
} from '../../hooks/useInspectionData'
import { usePopover } from '../../hooks/usePopover'

interface Props {
    inspectionData: InspectionDataResponse
    date: string
    user: User
    theme: Theme
    inspectionList: Inspection[]
    analyticData: InspectionAnalyticRes | null
    getInspectionListFromUnitInspection: (
        request: getInspectionListFromUnitInspectionRequest,
    ) => Promise<Inspection[]>
    navigateToInspectionDetail: (inspectionId: number) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        backgroundColor: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.grey[300]}`,
        width: 310,
    },
    content: {
        padding: theme.spacing(1),
        display: 'flex',
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
        alignItems: 'center',
    },
    text: {
        fontWeight: theme.typography.fontWeightBold,
    },
    completedText: {
        color: theme.palette.primary.dark,
    },
    scheduledText: {
        color: theme.palette.darkBlue.dark,
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popover: {
        padding: theme.spacing(2),
    },
    header: {
        borderBottom: `2px solid ${theme.palette.grey[500]}`,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
    },
    inspectionCard: {
        backgroundColor: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.grey[300]}`,
        margin: theme.spacing(1),
        maxHeight: 75,
        minHeight: 75,
    },
    analytics: {
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export const InspectionCell = (props: Props) => {
    const {
        inspectionData,
        date,
        user,
        theme,
        inspectionList,
        analyticData,
        getInspectionListFromUnitInspection,
        navigateToInspectionDetail,
    } = props

    const [loading, setLoading] = useState(false)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const classes = useStyles()
    const today = moment().format('YYYY-MM-DD')
    const formattedDate = moment(date).format('YYYY-MM-DD')
    const userId = user.id

    let content = ''
    let total = 0
    let completed = 0
    let textClass = ''

    const addUserAndAllUserData = (data: any) => {
        if (data) {
            if (data[userId] !== undefined) {
                completed += data[userId]
            }
            if (data[-1] !== undefined) {
                completed += data[-1]
            }
        }
    }

    const handleClick = async () => {
        setLoading(true)
        const request: getInspectionListFromUnitInspectionRequest = {
            params: {},
        }
        if (moment(formattedDate).isBefore(today)) {
            request.params = {
                completed_date: new Date(formattedDate).toISOString(),
                completed_by: userId,
            }
        } else if (formattedDate === today) {
            request.params = {
                today: true,
                assigned_to: userId,
            }
        } else {
            request.params = {
                start_date: new Date(formattedDate).toISOString(),
                assigned_to: userId,
            }
        }
        getInspectionListFromUnitInspection(request).finally(() => {
            setLoading(false)
        })
    }

    if (moment(formattedDate).isBefore(today)) {
        // Date is before today
        const priorData = inspectionData.prior[formattedDate]
        addUserAndAllUserData(priorData)
        if (completed > 0) {
            content = `${completed} Unit Inspections Completed`
            textClass = classes.completedText
        }
    } else if (formattedDate === today) {
        // Date is today
        const todayData = inspectionData.today[userId]
        const allUserData = inspectionData.today[-1]
        if (todayData) {
            completed = todayData.completed
            total = todayData.total
        }
        if (allUserData) {
            completed += allUserData.completed
            total += allUserData.total
        }
        if (total > 0) {
            content = `${completed}/${total} Unit Inspections Completed`
            textClass = classes.completedText
        }
    } else {
        // Date is after today
        const futureData = inspectionData.future[formattedDate]
        if (futureData) {
            if (futureData[userId] !== undefined) {
                total += futureData[userId]
            }
            if (futureData[-1] !== undefined) {
                total += futureData[-1]
            }
        }
        if (total > 0) {
            content = `${total} Unit Inspections Scheduled`
            textClass = classes.scheduledText
        }
    }

    if (content === '') {
        return <Container />
    }

    return (
        <Card className={`${classes.card} ${classes.clickable}`}>
            <CardContent
                className={classes.content}
                onClick={(e) => {
                    handleOpen(e)
                    handleClick()
                }}
            >
                <ClipboardTickSBIcon
                    style={{
                        width: 25,
                        height: 25,
                        marginRight: theme.spacing(1),
                    }}
                />
                <Typography
                    variant="body2"
                    className={`${classes.text} ${textClass}`}
                >
                    {content}
                </Typography>
            </CardContent>
            <InspectionCellPopover
                isOpen={isOpen}
                anchorEl={anchorEl}
                inspectionList={inspectionList}
                loading={loading}
                user={user}
                date={date}
                theme={theme}
                analyticData={analyticData}
                handleClose={handleClose}
                navigateToInspectionDetail={navigateToInspectionDetail}
            />
        </Card>
    )
}

interface InspectionCellPopoverProps {
    isOpen: boolean
    anchorEl: HTMLElement | null
    inspectionList: Inspection[]
    loading: boolean
    user: User
    date: string
    theme: Theme
    analyticData: InspectionAnalyticRes | null
    handleClose: () => void
    navigateToInspectionDetail: (inspectionId: number) => void
}

const InspectionCellPopover = (props: InspectionCellPopoverProps) => {
    const {
        isOpen,
        anchorEl,
        inspectionList,
        loading,
        user,
        date,
        analyticData,
        handleClose,
        navigateToInspectionDetail,
        theme,
    } = props

    const classes = useStyles()

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 150,
                    minWidth: 500,
                    maxWidth: 600,
                }}
            >
                <Typography variant="h6" className={classes.header}>
                    {user.name} {moment(date).format('MM-DD-YYYY')}
                </Typography>
                {loading ? (
                    <Container
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={50} />
                    </Container>
                ) : (
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                        }}
                    >
                        {analyticData && (
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                <Container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: theme.spacing(1),
                                    }}
                                >
                                    <Container
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className={classes.analytics}
                                        >
                                            Units:{' '}
                                            {analyticData.unit_inspection_count}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={classes.analytics}
                                        >
                                            Areas:{' '}
                                            {analyticData.area_inspection_count}
                                        </Typography>
                                    </Container>
                                    <Container
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className={classes.analytics}
                                        >
                                            Inventory Inspected:{' '}
                                            {
                                                analyticData.inventory_inspection_count
                                            }
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={classes.analytics}
                                        >
                                            Flagged Items:{' '}
                                            {
                                                analyticData.flagged_inventory_inspection_count
                                            }
                                        </Typography>
                                    </Container>
                                </Container>
                            </Container>
                        )}
                        <Container
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing(0, 1, 1, 1),
                                borderBottom: `2px solid ${theme.palette.primary.main}`,
                            }}
                        >
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                Name/Type
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                Schedule
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                Timeline
                            </Typography>
                        </Container>
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                maxHeight: 'calc(100vh - 500px)',
                                overflow: 'auto',
                            }}
                        >
                            {inspectionList.map((inspection) => (
                                <Card
                                    key={inspection.id}
                                    className={classes.inspectionCard}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: theme.spacing(1),
                                        padding: theme.spacing(1),
                                        alignItems: 'center',
                                        transition: 'background-color 0.3s',
                                    }}
                                >
                                    <Container
                                        style={{
                                            flex: 1,
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Tooltip
                                            title={`Go to ${inspection.name}`}
                                        >
                                            <IconButton
                                                style={{
                                                    marginRight: theme.spacing(
                                                        1,
                                                    ),
                                                }}
                                                onClick={() =>
                                                    navigateToInspectionDetail(
                                                        inspection.id,
                                                    )
                                                }
                                            >
                                                <ExitToAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Container
                                            style={{
                                                flexDirection: 'column',
                                                flex: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                className={classes.text}
                                            >
                                                {inspection.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={classes.text}
                                            >
                                                {
                                                    inspection.inspection_type
                                                        .name
                                                }
                                            </Typography>
                                        </Container>
                                        <Container style={{ flex: 1 }}>
                                            {inspection.schedule && (
                                                <Typography
                                                    variant="subtitle1"
                                                    className={classes.text}
                                                >
                                                    {inspection.schedule.name}
                                                </Typography>
                                            )}
                                        </Container>
                                        <Container
                                            style={{
                                                textAlign: 'right',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography variant="body2">
                                                {moment(
                                                    inspection.start_date,
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                            <Typography variant="body2">
                                                {moment(
                                                    inspection.end_date,
                                                ).format('MM-DD-YYYY')}
                                            </Typography>
                                        </Container>
                                    </Container>
                                </Card>
                            ))}
                        </Container>
                    </Container>
                )}
            </Container>
        </Popover>
    )
}
