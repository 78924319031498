import React, { useEffect, useState, useMemo } from 'react'
import {
    IconButton,
    Modal,
    Paper,
    Slide,
    Theme,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
} from '@material-ui/core'

import { LeaseAuditController } from '../../hooks/documentAudit'

import { Container } from '../../components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {
    AuditDocumentDetail,
    PipelineLog,
} from '../../models/DocumentAudit/types'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { getJobStage, getJobStatus } from '../../models/DocumentAudit/services'
interface Props {
    selectedDocOccuranceId: number
    theme: Theme
    leaseAuditController: LeaseAuditController
    onClose: () => void
}

const DetailModal = ({
    selectedDocOccuranceId,
    theme,
    leaseAuditController,
    onClose,
}: Props) => {
    const documents =
        leaseAuditController.leaseDocumentOccurances?.results || []

    const selectedDocument = documents?.find(
        (doc) => doc.id === selectedDocOccuranceId,
    )
    useEffect(() => {
        if (selectedDocument) {
            leaseAuditController.fetchPipelineLogs(selectedDocument.document.id)
        }
    }, [selectedDocOccuranceId])

    const open = selectedDocOccuranceId !== -1 && selectedDocument !== undefined

    const [levelFilter, setLevelFilter] = useState<LogLevel>('ALL')
    const [categoryFilter, setCategoryFilter] = useState<string>('ALL')
    const [searchTerm, setSearchTerm] = useState<string>('')

    const handleFilterSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLevelFilter(event.target.value as LogLevel)
    }

    const handleCategoryFilterSelect = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        setCategoryFilter(event.target.value as string)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const getUniqueCategories = (logs: PipelineLog[]): string[] => {
        const categories = new Set(logs.map((log) => log.log_category.name))
        return ['ALL', ...Array.from(categories)]
    }

    const uniqueCategories = useMemo(
        () => getUniqueCategories(leaseAuditController.pipelineLogs || []),
        [leaseAuditController.pipelineLogs],
    )

    const formatCategoryName = (category: string): string => {
        return category
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase())
    }
    const getStageText = (
        document: AuditDocumentDetail | undefined,
    ): string => {
        if (!document) return 'Unknown Stage'
        const stage =
            document.active_ticket?.jobs[document.active_ticket.jobs.length - 1]
        if (!stage) return 'Unknown Stage'
        return getJobStage(stage.stage)
    }

    const getStatusText = (
        document: AuditDocumentDetail | undefined,
    ): string => {
        if (!document) return 'Unknown Status'
        const status = document.active_ticket?.status
        if (!status) return 'Unknown Status'
        return getJobStatus(status)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: MODAL_H,
                            width: MODAL_W,
                            display: 'flex',
                            overflow: 'hidden',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        {/* header */}
                        <Container
                            style={{
                                flexDirection: 'row',
                                marginTop: theme.spacing(2),
                                marginLeft: theme.spacing(2),
                                marginRight: theme.spacing(2),
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            {/* summary */}
                            <Container
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    <Typography variant="body2">
                                        Id:{' '}
                                        <strong>
                                            {selectedDocument?.document.id}
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        File Name:{' '}
                                        <strong>
                                            {
                                                selectedDocument?.document
                                                    .file_name
                                            }
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        Stage:{' '}
                                        <strong>
                                            {getStageText(
                                                selectedDocument?.document,
                                            )}
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        Status:{' '}
                                        <strong>
                                            {getStatusText(
                                                selectedDocument?.document,
                                            )}
                                        </strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        Total Logs:{' '}
                                        <strong>
                                            {
                                                leaseAuditController
                                                    .pipelineLogs?.length
                                            }
                                        </strong>
                                    </Typography>
                                </div>
                            </Container>

                            {/* filter and search */}
                            <Container
                                style={{
                                    flex: 1,
                                    marginLeft: theme.spacing(1),
                                    flexDirection: 'column',
                                }}
                            >
                                {/* level filter */}
                                <Container
                                    style={{
                                        marginLeft: theme.spacing(2),
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FormControl
                                        component="fieldset"
                                        style={{
                                            marginTop: theme.spacing(1),
                                            marginBottom: theme.spacing(1),
                                            alignItems: 'center',
                                        }}
                                    >
                                        <RadioGroup
                                            aria-label="log-level-filter"
                                            name="log-level-filter"
                                            value={levelFilter}
                                            onChange={handleFilterSelect}
                                            row
                                        >
                                            {[
                                                'ALL',
                                                'INFO',
                                                'WARNING',
                                                'ERROR',
                                                'DEBUG',
                                            ].map((level) => (
                                                <FormControlLabel
                                                    key={level}
                                                    value={level}
                                                    control={
                                                        <Radio size="small" />
                                                    }
                                                    label={level}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Container>
                                <Container
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    {/* category filter */}
                                    <Container
                                        style={{
                                            flex: 1,
                                            marginLeft: theme.spacing(2),
                                        }}
                                    >
                                        <FormControl
                                            variant="outlined"
                                            style={{
                                                marginTop: theme.spacing(1),
                                                marginBottom: theme.spacing(1),
                                            }}
                                            fullWidth
                                        >
                                            <InputLabel id="category-filter-label">
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="category-filter-label"
                                                value={categoryFilter}
                                                onChange={
                                                    handleCategoryFilterSelect
                                                }
                                                label="Category"
                                            >
                                                {uniqueCategories.map(
                                                    (category) => (
                                                        <MenuItem
                                                            key={category}
                                                            value={category}
                                                        >
                                                            <FormControlLabel
                                                                value={category}
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        checked={
                                                                            categoryFilter ===
                                                                            category
                                                                        }
                                                                    />
                                                                }
                                                                label={formatCategoryName(
                                                                    category,
                                                                )}
                                                            />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Container>
                                    {/* log search */}
                                    <Container
                                        style={{
                                            marginLeft: theme.spacing(2),
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TextField
                                            label="Search Logs"
                                            variant="outlined"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            style={{
                                                marginRight: theme.spacing(1),
                                            }}
                                            fullWidth
                                        />
                                    </Container>
                                </Container>
                            </Container>
                            {/* close button */}
                            <Container
                                style={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <IconButton
                                    onClick={onClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Container>
                        </Container>
                        {selectedDocument && (
                            <DocumentLogs
                                key={`DOCUMENT-${selectedDocument.id}`}
                                document={selectedDocument.document}
                                theme={theme}
                                onClose={onClose}
                                pipelineLogs={leaseAuditController.pipelineLogs}
                                levelFilter={levelFilter}
                                categoryFilter={categoryFilter}
                                searchTerm={searchTerm}
                            />
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface DocumentLogsProps {
    document: AuditDocumentDetail
    pipelineLogs: PipelineLog[]
    theme: Theme
    onClose: () => void
    levelFilter: LogLevel
    categoryFilter: string
    searchTerm: string
}

type SortColumn = 'created_date' | 'log_level'
type SortDirection = 'asc' | 'desc'
type LogLevel = 'INFO' | 'WARNING' | 'ERROR' | 'DEBUG' | 'ALL'

const DocumentLogs = ({
    document,
    pipelineLogs,
    theme,
    onClose,
    levelFilter,
    categoryFilter,
    searchTerm,
}: DocumentLogsProps) => {
    const [sortColumn, setSortColumn] = useState<SortColumn>('created_date')
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc')

    const handleSort = (column: SortColumn) => {
        const isAsc = sortColumn === column && sortDirection === 'asc'
        setSortDirection(isAsc ? 'desc' : 'asc')
        setSortColumn(column)
    }

    const formatDateToEST = (utcDateString: string): string => {
        const date = new Date(utcDateString)
        const estDate = new Date(date.getTime() - 5 * 60 * 60 * 1000) // Convert to EST (UTC-5)

        const year = estDate.getUTCFullYear()
        const month = String(estDate.getUTCMonth() + 1).padStart(2, '0')
        const day = String(estDate.getUTCDate()).padStart(2, '0')
        const hours = estDate.getUTCHours()
        const minutes = String(estDate.getUTCMinutes()).padStart(2, '0')
        const seconds = String(estDate.getUTCSeconds()).padStart(2, '0')

        const ampm = hours >= 12 ? 'pm' : 'am'
        const formattedHours = hours % 12 || 12

        return `${year}-${month}-${day}___${formattedHours}:${minutes}:${seconds} ${ampm}`
    }

    const filteredAndSortedLogs = useMemo(() => {
        let logs = pipelineLogs || []
        if (levelFilter !== 'ALL') {
            logs = logs.filter((log) => log.log_level === levelFilter)
        }
        if (categoryFilter !== 'ALL') {
            logs = logs.filter(
                (log) => log.log_category.name === categoryFilter,
            )
        }
        if (searchTerm) {
            logs = logs.filter((log) =>
                log.log_text.toLowerCase().includes(searchTerm.toLowerCase()),
            )
        }
        return logs.sort((a, b) => {
            if (sortColumn === 'created_date') {
                return sortDirection === 'asc'
                    ? new Date(a.created_date).getTime() -
                          new Date(b.created_date).getTime()
                    : new Date(b.created_date).getTime() -
                          new Date(a.created_date).getTime()
            } else if (sortColumn === 'log_level') {
                return sortDirection === 'asc'
                    ? a.log_level.localeCompare(b.log_level)
                    : b.log_level.localeCompare(a.log_level)
            }
            return 0
        })
    }, [
        pipelineLogs,
        sortColumn,
        sortDirection,
        levelFilter,
        categoryFilter,
        searchTerm,
    ])

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortColumn === 'created_date'}
                                    direction={
                                        sortColumn === 'created_date'
                                            ? sortDirection
                                            : 'asc'
                                    }
                                    onClick={() => handleSort('created_date')}
                                >
                                    <Typography variant="subtitle2">
                                        <strong>Created Date</strong>
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2">
                                    <strong>Category</strong>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortColumn === 'log_level'}
                                    direction={
                                        sortColumn === 'log_level'
                                            ? sortDirection
                                            : 'asc'
                                    }
                                    onClick={() => handleSort('log_level')}
                                >
                                    <Typography variant="subtitle2">
                                        <strong>Level</strong>
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2">
                                    <strong>Log</strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedLogs.map((log, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {formatDateToEST(log.created_date)}
                                </TableCell>
                                <TableCell>{log.log_category.name}</TableCell>
                                <TableCell>{log.log_level}</TableCell>
                                <TableCell>{log.log_text}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default DetailModal

const MODAL_H = window.innerHeight * 0.95
const MODAL_W = window.innerWidth * 0.95
